import { Module } from 'vuex';
import { RootState } from '@core-app/store';
import { getData, setData } from '@core-app/utils/storage';
import {
  useLandingPageService,
  Domain,
  Template,
  ContentLocker,
} from '@core-app/services/landing-page.service';

// Setup initial states
const initialState = {
  availableOptions: {
    numOffersUnlock: [] as number[],
    domains: [] as Domain[],
    templates: [] as Template[],
    contentLockers: [] as ContentLocker[],
  },
  contentLockers: [] as ContentLocker[],
  contentLockerItem: {} as ContentLocker,
};
export type InitialState = typeof initialState;

// Get API Service
const landingPageService = useLandingPageService();

// Module storage functions
const moduleStorage = {
  moduleName: 'landingPageModule',
  fetch: async function () {
    return await getData(this.moduleName);
  },
  update: async function (state: typeof initialState) {
    await setData(this.moduleName, state);
  },
};

// Setup state module
const module: Module<InitialState, RootState> = {
  namespaced: true,
  state: initialState,
  getters: {},
  mutations: {
    updateState(state, data) {
      Object.assign(state, data);
    },
    mutateContentLockers(state, payload) {
      state.contentLockers = payload;
    },
    mutateContentLockerItem(state, payload) {
      state.contentLockerItem = payload;
    },
    mutateAvailableOptions(state, payload) {
      if (payload.numOffersUnlock) {
        state.availableOptions.numOffersUnlock = payload.numOffersUnlock;
      }
      if (payload.domains) {
        state.availableOptions.domains = payload.domains;
      }
      if (payload.templates) {
        state.availableOptions.templates = payload.templates;
      }
      if (payload.contentLockers) {
        state.availableOptions.contentLockers = payload.contentLockers;
      }
    },
  },

  actions: {
    async updateStateFromStorage({ commit }) {
      return moduleStorage.fetch().then((data) => {
        if (data) {
          commit('updateState', data);
        }
      });
    },

    async fetchContentLockers({ state, commit }, payload) {
      return await landingPageService.getContentLockers(payload).then(async (response) => {
        if (response.ok) {
          // Save data to store
          const data = response.data.data;

          if (data.contentLockers?.data) {
            commit('mutateContentLockers', data.contentLockers.data);
          }

          if (data.availableOptions) {
            commit('mutateAvailableOptions', data.availableOptions);
          }
        }
        // Update localstorage
        await moduleStorage.update(state);

        return response;
      });
    },

    async deleteContentLocker(context, payload: { id: number }) {
      return await landingPageService.deleteContentLocker(payload).then(async (response) => {
        return response;
      });
    },

    async createContentLocker(context, params) {
      return await landingPageService.createContentLocker(params.data).then(async (response) => {
        return response;
      });
    },

    async editContentLocker(context, params) {
      return await landingPageService
        .editContentLocker(params.id, params.data)
        .then(async (response) => {
          return response;
        });
    },

    async deleteContentLockerImage(context, payload: { id: number; mediaFileId: number }) {
      return await landingPageService.deleteContentLockerImage(payload).then(async (response) => {
        return response;
      });
    },

    async uploadContentLockerImages(
      context,
      payload: {
        contentLockerId: number;
        files: File[];
        uploadedImageSelectionIndex: { [key: string]: number };
      }
    ) {
      return await landingPageService.uploadContentLockerImages(payload).then(async (response) => {
        return response;
      });
    },
  },
};

export default module;
