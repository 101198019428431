// import { createStore, createLogger } from 'vuex';
import { createStore } from 'vuex';

import appModule, { InitialState as AppState } from './app.module';
import authModule, { InitialState as AuthState } from './auth.module';
import dashboardModule, { InitialState as DashboardState } from './dashboard.module';
import landingPageModule, { InitialState as LandingPageState } from './landing-page.module';
import leaderboardModule, { InitialState as LeaderboardState } from './leaderboard.module';
import offerModule, { InitialState as OfferState } from './offer.module';
import permissionsModule, { InitialState as PermissionsState } from './permissions.module';
import profileModule, { InitialState as ProfileState } from './profile.module';
import statsModule, { InitialState as StatsState } from './stats.module';
import teamsModule, { InitialState as TeamsState } from './teams.module';

const rootState = {};
export interface RootState {
  appModule?: AppState;
  authModule?: AuthState;
  dashboardModule?: DashboardState;
  landingPageModule?: LandingPageState;
  leaderboardModule?: LeaderboardState;
  offerModule?: OfferState;
  permissionsModule?: PermissionsState;
  profileModule?: ProfileState;
  statsModule?: StatsState;
  teamsModule?: TeamsState;
}

export default createStore<RootState>({
  state: rootState,
  actions: {
    async initializeStore(context) {
      await Promise.all([
        // Initialize AppModule
        context.dispatch('appModule/updateStateFromStorage'),

        // Initialize AuthModule
        context.dispatch('authModule/updateStateFromStorage'),

        // Initialize DashboardModule
        context.dispatch('dashboardModule/updateStateFromStorage'),

        // Initilize LandingPageModule
        context.dispatch('landingPageModule/updateStateFromStorage'),

        // Initialize LeaderboardModule
        context.dispatch('leaderboardModule/updateStateFromStorage'),

        // Initialize OfferModule
        context.dispatch('offerModule/updateStateFromStorage'),

        // Initialize PermissionsModule
        context.dispatch('permissionsModule/updateStateFromStorage'),

        // Initialize ProfileModule
        context.dispatch('profileModule/updateStateFromStorage'),

        // Initialize StatsModule
        context.dispatch('statsModule/updateStateFromStorage'),

        // Initialize TeamsModule
        context.dispatch('teamsModule/updateStateFromStorage'),
      ]);

      // After finishing the state from storage, update loading stage
      context.commit('appModule/setAppLoadingStage', 1);
    },
  },

  modules: {
    appModule,
    authModule,
    dashboardModule,
    landingPageModule,
    leaderboardModule,
    offerModule,
    permissionsModule,
    profileModule,
    statsModule,
    teamsModule,
  },
  // plugins: import.meta.env.VITE_APP_ENV !== 'production' ? [createLogger()] : [],
});
