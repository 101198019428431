import { useApi } from './api';

export const useLeaderboardService = () => {
  const api = useApi();

  // const getLeaderboardNetwork = async () => {
  //   return await api.get('/leaderboard/network');
  // };

  const getLeaderboardAll = async () => {
    return await api.get('/leaderboard/all');
  };

  return {
    api,
    // getLeaderboardNetwork,
    getLeaderboardAll,
  };
};
