import { createRouter, createWebHistory } from '@ionic/vue-router';
// import { Plugins } from '@capacitor/core';
import { Capacitor } from '@capacitor/core';
import { BranchDeepLinks, BranchInitEvent } from 'capacitor-branch-deep-links';
import { getData } from '@core-app/utils/storage';

import { Analytics } from '@core-app/utils/plugins';
import { filters } from '@core-app/utils/filters';
import store from '@core-app/store';

import { isTapmob, isKVRMA } from '@core-app/utils/helpers';
import tapmobRoutes from './tapmob';
import kvrmaRoutes from './kvrma';

// Create router instance
const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: isTapmob ? tapmobRoutes : isKVRMA ? kvrmaRoutes : [],
});

// Router middleware
router.beforeEach(async (to, from, next) => {
  // Set dark mode manually for the welcome page, set to light on public routes
  if (to.path === '/welcome') {
    document.body.classList.toggle('dark', true);
  } else {
    if (to.matched.some((record) => record.meta.publicRoute)) {
      document.body.classList.toggle('dark', false);
    }
  }

  // Hide if not /app/dashboard, load it on per-route guard for routes file
  if (to.path !== '/app/dashboard') {
    Analytics.hideIntercomLauncher();
  }

  // Process if on initial load
  let authData;
  if (from.path === '/') {
    // Get auth data from local storage on root load
    authData = await getData('authModule');
  } else {
    authData = store.state.authModule;
  }

  // public routes, check for token and redirect
  if (to.matched.some((record) => record.meta.publicRoute)) {
    if (authData.rememberMe && authData.token) {
      next({ path: '/app' });
    } else {
      next();
    }
  }

  // If there is no token on the private routes, then redirect back to the welcome screen
  if (to.matched.some((record) => !record.meta.publicRoute)) {
    if (!authData.token) {
      next({ path: '/welcome' });
    } else {
      next();
    }
  }
});

// After page loads, process scripts
router.afterEach((to) => {
  const name = typeof to.name === 'string' ? to.name : to.fullPath;
  Analytics.setScreenName(name);
});

export const initializeRouter = () => {
  if (Capacitor.getPlatform() !== 'web') {
    // Capacitor Deep Links: https://capacitorjs.com/docs/guides/deep-links
    // App.addListener('appUrlOpen', function(data) {
    //   // Check to make sure domain name is tpmb.app
    //   const { hostname } = new URL(data.url);
    //   if (hostname === 'tpmb.app') {
    //     // We only push to the route if there is a slug present
    //     // Example url: https://tpmb.app/register
    //     // slug = /register
    //     const slug = data.url.split('.app').pop();
    //     if (slug) {
    //       router.push({ path: slug });
    //     }
    //   }
    // });

    // https://www.npmjs.com/package/capacitor-branch-deep-links
    BranchDeepLinks.addListener('init', (event: BranchInitEvent) => {
      // Check '+clicked_branch_link' before deciding whether to use your Branch routing logic
      if (event.referringParams['+clicked_branch_link']) {
        // Get custom_string and parse as JSON object
        const defaultParams = { referralId: '', slug: '/', resetPasswordToken: '' };
        const customString = event.referringParams.custom_string;

        // Convert customString to json object
        const params =
          typeof customString === 'string'
            ? filters.toCamelcaseKeys(JSON.parse(customString))
            : defaultParams;

        // If there is a resetPasswordToken, then store the token in authModule
        if (params.resetPasswordToken !== '') {
          store.dispatch('authModule/setResetPasswordToken', params.resetPasswordToken);
        }

        // If there is a referralId, then update the registration form
        if (params.referralId !== '') {
          store.dispatch('authModule/setRegistrationForm', { referralId: params.referralId });
        }

        // If there is a slug, then redirect to that page
        if (params.slug) {
          router.push({ path: params.slug });
        }

        // If there is a $deep_link path, attempt to visit that page
        const deepLink = event.referringParams['$deeplink_path'] ?? '';
        if (deepLink !== '') {
          router.push({ path: deepLink });
        }
      }
    });

    // BranchDeepLinks.addListener('initError', (error: any) => {
    //   console.error('BranchDeepLinks::addListener(initError) => ', error);
    // });
  }
};

export default router;
