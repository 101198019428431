import { createApp } from 'vue';
import { IonicVue } from '@ionic/vue';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { Capacitor } from '@capacitor/core';

import App from '@core-app/App.vue';
import store from '@core-app/store';
import router, { initializeRouter } from '@core-app/router';
import { Analytics } from '@core-app/utils/plugins';
import { appVersion, sentryUrl, nodeEnvironment, isProd } from '@core-app/utils/helpers';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing'; // Must come second after @sentry/vue
import './styles';

// Prepare Ionic Vue App
const app = createApp(App)
  .use(IonicVue, {
    backButtonText: '',
    mode: 'ios',
  })
  .use(store)
  .use(router);

if (isProd) {
  // Remove any console.log() commands in production
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  console.log = function () {};

  // Make sure errorHandler is above Sentry.init()
  // https://stackoverflow.com/questions/53300040/vue-cli-3-with-sentry-how-to-use-vues-config-errorhandler
  // Setup error handler
  // https://v3.vuejs.org/api/application-config.html#errorhandler
  // https://github.com/getsentry/sentry-javascript/issues/2292#issuecomment-547523076
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  app.config.errorHandler = async (error: any) => {
    const exception = error.error || error.message || error.originalError || error;
    Sentry.captureException(exception);
  };

  // Initialize Sentry
  Sentry.init({
    app,
    dsn: sentryUrl,
    environment: nodeEnvironment,
    release: 'tapmob-mobile@' + appVersion + '-tapmob',
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
    ],
    sampleRate: 1.0,
    tracesSampleRate: 0.1,
    attachStacktrace: true,
    ignoreErrors: ['Non-Error exception captured'],
  });

  // Initialize Analytics
  (async () => {
    await Analytics.init();
  })();
}

// Once it's ready, mount the app
router.isReady().then(async () => {
  // Initialize the store first, then initialize router
  // This ensures store data is available
  await store.dispatch('initializeStore');

  // Dispatch AppModule::initializeApp
  await store.dispatch('appModule/initializeApp');

  // Mount App
  app.mount('#app');

  // Initialize router after app.mount() to prevent UI issues and after initializeRoute/initializeApp
  initializeRouter();

  // Mount PWA Elements for web after app has been rendered the first time
  if (Capacitor.getPlatform() === 'web') {
    defineCustomElements(window);
  }

  // Verify token if it exists, handle redirect
  await store.dispatch('authModule/verifyToken');

  // If not on public route, then check for updates
  if (!router.currentRoute.value.meta.publicRoute) {
    // Check App Update for Native Builds
    const nativeUpdateAvailable = await store.dispatch('appModule/checkAppUpdateNative');

    // Check App Update for Over-The-Air build if native update not available
    if (!nativeUpdateAvailable) {
      await store.dispatch('appModule/checkAppUpdateOverTheAir');
    }
  }

  // Add extra listeners for sentry
  // https://github.com/getsentry/sentry-javascript/issues/2925#issuecomment-821770572
  window.addEventListener('error', (event) => {
    Sentry.captureException(event);
  });
  window.addEventListener('unhandledrejection', (event) => {
    Sentry.captureException(event);
  });
});

/**
 * For development purposes. Use local registration for production
 * https://ionicframework.com/docs/vue/quickstart#optimizing-your-build
 */

import * as ionic from '@ionic/vue';
import * as icons from 'ionicons/icons';

app.config.globalProperties.$icons = icons;
app.component('ion-page', ionic.IonPage);
app.component('ion-action-sheet', ionic.IonActionSheet);
app.component('ion-alert', ionic.IonAlert);
app.component('ion-app', ionic.IonApp);
app.component('ion-avatar', ionic.IonAvatar);
app.component('ion-back-button', ionic.IonBackButton);
app.component('ion-backdrop', ionic.IonBackdrop);
app.component('ion-badge', ionic.IonBadge);
app.component('ion-button', ionic.IonButton);
app.component('ion-buttons', ionic.IonButtons);
app.component('ion-card', ionic.IonCard);
app.component('ion-card-content', ionic.IonCardContent);
app.component('ion-card-header', ionic.IonCardHeader);
app.component('ion-card-subtitle', ionic.IonCardSubtitle);
app.component('ion-card-title', ionic.IonCardTitle);
app.component('ion-checkbox', ionic.IonCheckbox);
app.component('ion-chip', ionic.IonChip);
app.component('ion-col', ionic.IonCol);
app.component('ion-content', ionic.IonContent);
app.component('ion-datetime', ionic.IonDatetime);
app.component('ion-fab', ionic.IonFab);
app.component('ion-fab-button', ionic.IonFabButton);
app.component('ion-fab-list', ionic.IonFabList);
app.component('ion-footer', ionic.IonFooter);
app.component('ion-grid', ionic.IonGrid);
app.component('ion-header', ionic.IonHeader);
app.component('ion-icon', ionic.IonIcon);
app.component('ion-img', ionic.IonImg);
app.component('ion-infinite-scroll', ionic.IonInfiniteScroll);
app.component('ion-infinite-scroll-content', ionic.IonInfiniteScrollContent);
app.component('ion-input', ionic.IonInput);
app.component('ion-item', ionic.IonItem);
app.component('ion-item-divider', ionic.IonItemDivider);
app.component('ion-item-group', ionic.IonItemGroup);
app.component('ion-item-option', ionic.IonItemOption);
app.component('ion-item-options', ionic.IonItemOptions);
app.component('ion-item-sliding', ionic.IonItemSliding);
app.component('ion-label', ionic.IonLabel);
app.component('ion-list', ionic.IonList);
app.component('ion-list-header', ionic.IonListHeader);
app.component('ion-loading', ionic.IonLoading);
app.component('ion-menu', ionic.IonMenu);
app.component('ion-menu-button', ionic.IonMenuButton);
app.component('ion-menu-toggle', ionic.IonMenuToggle);
app.component('ion-modal', ionic.IonModal);
app.component('ion-nav', ionic.IonNav);
app.component('ion-nav-link', ionic.IonNavLink);
app.component('ion-note', ionic.IonNote);
app.component('ion-picker', ionic.IonPicker);
app.component('ion-popover', ionic.IonPopover);
app.component('ion-progress-bar', ionic.IonProgressBar);
app.component('ion-radio', ionic.IonRadio);
app.component('ion-radio-group', ionic.IonRadioGroup);
app.component('ion-range', ionic.IonRange);
app.component('ion-refresher', ionic.IonRefresher);
app.component('ion-refresher-content', ionic.IonRefresherContent);
app.component('ion-reorder', ionic.IonReorder);
app.component('ion-reorder-group', ionic.IonReorderGroup);
app.component('ion-ripple-effect', ionic.IonRippleEffect);
app.component('ion-router-outlet', ionic.IonRouterOutlet);
app.component('ion-row', ionic.IonRow);
app.component('ion-searchbar', ionic.IonSearchbar);
app.component('ion-segment', ionic.IonSegment);
app.component('ion-segment-button', ionic.IonSegmentButton);
app.component('ion-select', ionic.IonSelect);
app.component('ion-select-option', ionic.IonSelectOption);
app.component('ion-skeleton-text', ionic.IonSkeletonText);
app.component('ion-spinner', ionic.IonSpinner);
app.component('ion-split-pane', ionic.IonSplitPane);
// app.component('ion-tab', ionic.IonTab);
app.component('ion-tab-bar', ionic.IonTabBar);
app.component('ion-tab-button', ionic.IonTabButton);
app.component('ion-tabs', ionic.IonTabs);
app.component('ion-text', ionic.IonText);
app.component('ion-textarea', ionic.IonTextarea);
app.component('ion-thumbnail', ionic.IonThumbnail);
app.component('ion-title', ionic.IonTitle);
app.component('ion-toast', ionic.IonToast);
app.component('ion-toggle', ionic.IonToggle);
app.component('ion-toolbar', ionic.IonToolbar);

// https://ionicframework.com/docs/vue/virtual-scroll
import * as VueVirtualScroller from 'vue-virtual-scroller';
app.component('recycle-scroller', VueVirtualScroller.RecycleScroller);
app.component('dynamic-scroller', VueVirtualScroller.DynamicScroller);
app.component('dynamic-scroller-item', VueVirtualScroller.DynamicScrollerItem);
