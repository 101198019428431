import { useStore } from 'vuex';
import router from '@core-app/router';
import { Capacitor } from '@capacitor/core';
import {
  ActionPerformed,
  PushNotifications,
  PushNotificationSchema,
  Token,
} from '@capacitor/push-notifications';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import { BranchDeepLinks } from 'capacitor-branch-deep-links';
import { Badge } from '@capawesome/capacitor-badge';

import { isProd, isStaging, isLoggedIn } from './helpers';
// import { Mixpanel, MixpanelPeople } from '@ionic-native/mixpanel';
// import { Mixpanel } from '@houseninja/capacitor-mixpanel';
import { Mixpanel, MixpanelPeople } from '@awesome-cordova-plugins/mixpanel';
import { Intercom } from '@capacitor-community/intercom';
import * as Sentry from '@sentry/vue';

interface User {
  name: string;
  accountId: number;
  email: string;
  createdAt: string;
}

export const Analytics = {
  captureMessage: (message: string) => {
    if (isProd) {
      Sentry.captureMessage(message);
    }
  },

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  captureError: (err: any) => {
    if (isProd) {
      Sentry.captureException(err);
    }
  },

  init: async () => {
    // Not implemented on iOS/Android. Mixpanel is initialized automatically.
    // https://github.com/houseninjadojo/capacitor-mixpanel/blob/c89f5458b0059722a10af572aff0bd58dd15789e/ios/Plugin/MixpanelPlugin.swift - Line 24
    // https://github.com/houseninjadojo/capacitor-mixpanel/blob/c89f5458b0059722a10af572aff0bd58dd15789e/android/src/main/java/co/houseninja/plugins/mixpanel/MixpanelPlugin.java
    if ((isProd || isStaging) && Capacitor.getPlatform() !== 'web') {
      const mixpanelToken = import.meta.env.VITE_APP_MIXPANEL;
      try {
        await Mixpanel.init(mixpanelToken);
        // await Mixpanel.initialize(mixpanelToken);
      } catch (error) {
        console.log('error: mixpanel.init():', error);
        // Sentry.captureException(error);
      }
    }
  },

  // Track the name of the screen/page
  setScreenName: async (name: string) => {
    if ((isProd || isStaging) && Capacitor.getPlatform() !== 'web') {
      // Firebase
      FirebaseAnalytics.setScreenName({ screenName: name });

      // Mixpanel
      try {
        await Mixpanel.track(`Viewed ${name} page`);
        // await Mixpanel.track({ event: `Viewed ${name} page`, properties: {} });
      } catch (error) {
        console.log('error: mixpanel.track():', error);
        // Sentry.captureException(error);
      }

      // Intercom
      if (isLoggedIn()) {
        Intercom.logEvent({ name: `Viewed ${name} page` });
      }
    }
  },

  setUser: async (user: User) => {
    if ((isProd || isStaging) && Capacitor.getPlatform() !== 'web') {
      const userId = user?.accountId?.toString() ?? 0;

      // Firebase
      FirebaseAnalytics.setUserId({ userId });

      // Branch.io
      try {
        BranchDeepLinks.setIdentity({ newIdentity: userId });
      } catch (error) {
        console.log('error: BranchDeepLinks.setIdentify():', error);
        // Sentry.captureException(error);
      }

      // Mixpanel
      try {
        await Mixpanel.identify(userId);
        // await Mixpanel.identify({ distinctId: userId });

        await MixpanelPeople.set({
          USER_ID: userId,
          $email: user.email,
          $created: user.createdAt,
          $name: user.name,
        });

        // await Mixpanel.setProfile({
        //   properties: {
        //     USER_ID: userId,
        //     $email: user.email,
        //     $created: user.createdAt,
        //     $name: user.name,
        //   },
        // });
      } catch (error) {
        console.log('error: mixpanel.identify():', error);
        // Sentry.captureException(error);
      }

      // Intercom
      Intercom.registerIdentifiedUser({ userId: userId, email: user.email });
      Intercom.updateUser({
        name: user.name,
        email: user.email,
        customAttributes: { created: user.createdAt },
      });
    }
  },
  displayIntercomLauncher: () => {
    if ((isProd || isStaging) && Capacitor.getPlatform() !== 'web') {
      Intercom.setBottomPadding({ value: '60' });
      Intercom.displayLauncher();
    }
  },
  hideIntercomLauncher: () => {
    if ((isProd || isStaging) && Capacitor.getPlatform() !== 'web') {
      Intercom.hideLauncher();
    }
  },
};

export const PushHelper = {
  setBadgeCount: async (count: number) => {
    if (Capacitor.getPlatform() !== 'web') {
      const permissions = await Badge.checkPermissions();
      if (permissions.display === 'granted') {
        if (count === 0) {
          await Badge.clear();
        } else {
          await Badge.set({ count });
        }
      }
    }
  },

  initialize: () => {
    // if (!isProd && Capacitor.getPlatform() === 'web') {
    //   const store = useStore();
    //   store.dispatch('profileModule/updateNotificationsToken', 'test-token-123');
    // }
    if (Capacitor.getPlatform() !== 'web') {
      const store = useStore();

      PushNotifications.requestPermissions().then((permission) => {
        // alert(`PushNotifications.requestPermissions<br>${JSON.stringify(permission)}`);
        if (permission.receive === 'granted') {
          // Register with Apple / Google to receive push via APNS/FCM
          PushNotifications.register();
        } else {
          // No permission for push granted
        }
      });

      // On success, we should be able to receive notifications
      PushNotifications.addListener('registration', (token: Token) => {
        // alert(`PushNotifications.registration<br>${JSON.stringify(token)}`);
        store.dispatch('profileModule/updateNotificationsToken', token.value);
      });

      // Some issue with our setup and push will not work
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
      PushNotifications.addListener('registrationError', (error: any) => {
        // alert('PushNotifications::registrationError: ' + JSON.stringify(error));
        console.log('PushNotifications::Error: ' + JSON.stringify(error));
        // Sentry.captureException(error);
      });

      // Show us the notification payload if the app is open on our device
      PushNotifications.addListener(
        'pushNotificationReceived',
        (notification: PushNotificationSchema) => {
          console.log('PushNotifications::Push received: ' + JSON.stringify(notification));
          // alert('PushNotifications::Push received: ' + JSON.stringify(notification));
        }
      );

      // Method called when tapping on a notification
      PushNotifications.addListener(
        'pushNotificationActionPerformed',
        (notification: ActionPerformed) => {
          console.log('PushNotifications::Push action performed: ', notification);
          // alert('PushNotifications::Push action performed: ' + JSON.stringify(notification));

          // Extract the data
          const data = notification.notification.data;

          // If data.path exists and has a path to redirect to
          if (data?.path && data?.path !== '') {
            router.push(data.path);
          }
        }
      );
    }
  },
};
