import { Module } from 'vuex';
import { RootState } from '@core-app/store';
import { PushHelper } from '@core-app/utils/plugins';
import { getData, setData } from '@core-app/utils/storage';
import {
  useProfileService,
  useBillingService,
  Profile,
  Billing,
  UserOptions,
} from '@core-app/services/profile.service';

import { useNotificationsService, Notification } from '@core-app/services/notifications.service';

export interface Invoice {
  actions: number;
  affiliateId: number | string;
  amount: string;
  currency: string;
  datetime: string;
  endDate: string;
  id: string | number;
  isPaid: string | boolean;
  memo: string;
  notes: string;
  receiptId: number;
  startDate: string;
  status: string;
}

interface InvoiceSummary {
  id: number;
  affiliateId: number;
  receiptId: number;
  datetime: string;
  startDate: string;
  endDate: string;
  actions: number | string;
  amount: number | string;
  currency: string;
  isPaid: number;
  status: string;
  memo: string;
  notes: string;
}

export interface InvoiceItem {
  id: number;
  offerId: number;
  offerName: string;
  actions: number | string;
  amount: number | string;
  type: 'stats' | 'referrals' | 'adjustment';
  memo: string;
  thumbnailUrl: string;
}

export interface ConversionSoundOption {
  name: string;
  filename: string;
}

export interface ConversionTemplateOption {
  id: number;
  optionTitle: string;
  optionBodyAlt1: string;
  optionBodyAlt2: string;
}

// Setup initial states
const initialState = {
  darkMode: false,
  balance: {
    total: 0,
    current: 0,
    previous: 0,
  },
  previousBalance: 0,
  profile: {} as Profile,

  billing: {} as Billing,
  billingInvoices: [] as Invoice[],
  billingPayments: {},
  billingUpdateMethod: 'edit',

  invoiceSummary: {} as InvoiceSummary,
  invoiceItems: [] as InvoiceItem[],

  onboardingViewed: false,
  userOptions: {} as UserOptions,
  formOptions: {
    conversionSounds: [] as ConversionSoundOption[],
    conversionTemplates: [] as ConversionTemplateOption[],
  },
  notificationToken: '',
  notifications: [] as Notification[],
  unreadNotificationCount: 0,
  notificationsPage: 1,
};
export type InitialState = typeof initialState;

// Get API Service
const profileService = useProfileService();
const billingService = useBillingService();
const notificationsService = useNotificationsService();

// Module storage functions
const moduleStorage = {
  moduleName: 'profileModule',
  fetch: async function () {
    return await getData(this.moduleName);
  },
  update: async function (state: typeof initialState) {
    await setData(this.moduleName, state);
  },
};

// Setup state module
const module: Module<InitialState, RootState> = {
  namespaced: true,
  state: initialState,
  getters: {},
  mutations: {
    updateState(state, data) {
      Object.assign(state, data);
    },
    toggleDarkMode(state) {
      state.darkMode = !state.darkMode;
    },
    updateBalance(state, payload) {
      state.balance = payload;
    },
    updateProfile(state, payload) {
      state.profile = payload;
    },
    updateBilling(state, payload) {
      state.billing = payload;
    },
    updateBillingInvoices(state, payload) {
      state.billingInvoices = payload;
    },
    updateInvoiceSummary(state, payload) {
      state.invoiceSummary = payload;
    },
    updateInvoiceItems(state, payload) {
      state.invoiceItems = payload;
    },
    updateBillingPayments(state, payload) {
      state.billingPayments = payload;
    },
    updateBillingUpdateMethod(state, payload) {
      state.billingUpdateMethod = payload;
    },
    updateOnboardingViewed(state, payload) {
      state.onboardingViewed = payload;
    },
    updateUserOptions(state, payload) {
      state.userOptions = payload;
    },

    mutateFormOptions(state, payload) {
      state.formOptions = payload;
    },

    setNotificationOptions(state, payload) {
      state.userOptions.notifications = payload;
    },
    setNotificationsToken(state, payload) {
      state.notificationToken = payload;
    },

    setUnreadNotificationCount(state, payload) {
      state.unreadNotificationCount = payload;
    },

    resetNotifications(state) {
      state.notifications = [];
      state.notificationsPage = 1;
    },
    incrementNotificationsPage(state) {
      state.notificationsPage = state.notificationsPage + 1;
    },

    updateExistingNotifications(state, payload) {
      // Update note if it exists in the payload
      state.notifications = state.notifications.map((note: Notification) => {
        const newNote = payload.find((newNotes: Notification) => newNotes.id === note.id);
        return newNote ?? note;
      });
    },

    prependNotifications(state, payload) {
      // Get IDs of existing notifications
      const existingIds = state.notifications.map((note: Notification) => note.id);

      // New Payload
      const newNotifications = payload.filter(
        (note: Notification) => !existingIds.includes(note.id)
      );

      // Append to the list
      state.notifications = [...newNotifications, ...state.notifications];
    },
    appendNotifications(state, payload) {
      // Get IDs of existing notifications
      const existingIds = state.notifications.map((note: Notification) => note.id);

      // New Payload
      const newNotifications = payload.filter(
        (note: Notification) => !existingIds.includes(note.id)
      );

      // Append to the list
      state.notifications = [...state.notifications, ...newNotifications];
    },
  },

  actions: {
    async updateStateFromStorage({ commit }) {
      return moduleStorage.fetch().then((data) => {
        if (data) {
          commit('updateState', data);
        }
      });
    },

    async setReferralLink({ commit, state }, payload) {
      commit('updateReferralLink', payload);
      await moduleStorage.update(state);
    },

    async setOnboardingViewed({ commit, state }, payload) {
      commit('updateOnboardingViewed', payload);
      await moduleStorage.update(state);
    },

    async changeBillingUpdateMethod({ commit, state }, payload) {
      commit('updateBillingUpdateMethod', payload);
      await moduleStorage.update(state);
    },

    async toggleDarkMode({ commit, state }) {
      commit('toggleDarkMode');
      await moduleStorage.update(state);
    },

    async fetchProfile({ commit, state }) {
      return await profileService.getProfile().then(async (response) => {
        if (response.ok) {
          // Save data to store
          const data = response?.data?.data ?? {};

          if (data?.profile) {
            // Replace referral link in the response with generated link
            // See plugins.ts
            // data.profile.referralLink = state.newReferralLink;
            commit('updateProfile', data.profile);
          }

          if (data?.billing) {
            commit('updateBilling', data.billing);
          }

          if (data?.userOptions) {
            commit('updateUserOptions', data.userOptions);
          }

          if (data?.formOptions) {
            commit('mutateFormOptions', data.formOptions);
          }
          // Update localstorage
          await moduleStorage.update(state);
        } else {
          console.log('Unable to fetch profile');
        }
        return response;
      });
    },

    async fetchBillingHistory({ commit, state }) {
      return await billingService.getBillingHistory().then(async (response) => {
        if (response.ok) {
          // Save data to store
          const data = response?.data?.data ?? {};

          if (data?.balance) {
            commit('updateBalance', data.balance);
          }
          if (data?.invoices) {
            commit('updateBillingInvoices', data.invoices);
          }
          if (data?.payments) {
            commit('updateBillingPayments', data.payments);
          }

          // Update localstorage
          await moduleStorage.update(state);
        } else {
          console.log('Unable to fetch profile');
        }
        return response;
      });
    },

    async fetchBillingInvoice({ commit, state }, payload) {
      return await billingService.getBillingInvoice(payload).then(async (response) => {
        if (response.ok) {
          // Save data to store
          const data = response?.data?.data ?? {};

          if (data?.invoiceSummary) {
            commit('updateInvoiceSummary', data.invoiceSummary);
          }
          if (data?.invoiceItems) {
            commit('updateInvoiceItems', data.invoiceItems);
          }

          // Update localstorage
          await moduleStorage.update(state);
        } else {
          console.log('Unable to fetch profile');
        }
        return response;
      });
    },

    async updateProfile({ commit, state }, payload) {
      return await profileService.updateProfile(payload).then(async (response) => {
        if (response.ok) {
          // Update payment method in profile
          commit('updateProfile', { ...state.profile, ...payload });

          // Update localstorage
          await moduleStorage.update(state);
        } else {
          console.log('Unable to update profile');
        }
        return response;
      });
    },

    async updateOnboardingViewed({ dispatch }, payload) {
      return await profileService
        .updateOnboardingViewed({ onboardingViewed: payload })
        .then(async (response) => {
          dispatch('setOnboardingViewed', payload);
          return response;
        });
    },

    async uploadAvatar(context, payload: { file: File }) {
      return await profileService.uploadAvatar(payload).then(async (response) => {
        return response;
      });
    },

    async updateNotificationOptions({ commit, state }, payload) {
      return await notificationsService
        .updateNotificationOptions(payload)
        .then(async (response) => {
          commit('setNotificationOptions', payload);
          await moduleStorage.update(state);
          return response;
        });
    },

    async resetNotifications({ commit, state }) {
      commit('resetNotifications');
      await moduleStorage.update(state);
    },

    async updateNotifications({ commit, state }, payload) {
      return await notificationsService.updateNotifications(payload).then(async (response) => {
        if (response.ok) {
          // Save data to store
          const data = response?.data?.data ?? {};

          // Set notifications and unread count
          if (data?.notifications) {
            commit('updateExistingNotifications', data.notifications);
          }
          if (data?.unreadCount !== undefined) {
            commit('setUnreadNotificationCount', data.unreadCount);

            // Set the count
            PushHelper.setBadgeCount(data.unreadCount);
          }

          // OK to save to local storage
          await moduleStorage.update(state);
        } else {
          console.log('Unable to update notifications');
        }
        return response;
      });
    },

    async markAllAsRead({ commit, state }) {
      return await notificationsService.markAllAsRead().then(async (response) => {
        if (response.ok) {
          // Save data to store
          const data = response?.data?.data ?? {};

          // Set notifications and unread count
          if (data?.notifications) {
            commit('updateExistingNotifications', data.notifications);
          }
          if (data?.unreadCount !== undefined) {
            commit('setUnreadNotificationCount', data.unreadCount);

            // Set the count
            PushHelper.setBadgeCount(data.unreadCount);
          }

          // OK to save to local storage
          await moduleStorage.update(state);
        } else {
          console.log('Unable to update notifications');
        }
        return response;
      });
    },

    async fetchNewNotifications({ commit, state }, payload) {
      return await notificationsService.getNotifications(payload).then(async (response) => {
        if (response.ok) {
          // Save data to store
          const data = response?.data?.data ?? {};

          // Set notifications and unread count
          if (data?.notifications) {
            commit('updateExistingNotifications', data.notifications);
            commit('prependNotifications', data.notifications);
          }
          if (data?.unreadCount !== undefined) {
            commit('setUnreadNotificationCount', data.unreadCount);

            // Set the count
            PushHelper.setBadgeCount(data.unreadCount);
          }

          // OK to save to local storage
          await moduleStorage.update(state);
        } else {
          console.log('Unable to fetch notifications');
        }
        return response;
      });
    },

    async fetchOldNotifications({ commit, state }) {
      // Increment the notifications page
      commit('incrementNotificationsPage');

      return await notificationsService
        .getNotifications({ page: state.notificationsPage })
        .then(async (response) => {
          if (response.ok) {
            // Save data to store
            const data = response?.data?.data ?? {};

            // NOTE: Don't save to localstorage to prevent localstorage from saving old messages
            if (data?.notifications) {
              commit('updateExistingNotifications', data.notifications);
              commit('appendNotifications', data.notifications);
            }
            if (data?.unreadCount) {
              commit('setUnreadNotificationCount', data.unreadCount);

              // Set the count
              PushHelper.setBadgeCount(data.unreadCount);
            }
          } else {
            console.log('Unable to fetch notifications');
          }
          return response;
        });
    },

    async updateNotificationsToken({ commit, state }, payload) {
      // If the new token doesn't equal what's in the state, then update
      if (state.notificationToken !== payload) {
        return await notificationsService
          .updateNotificationsToken({ token: payload })
          .then(async (response) => {
            commit('setNotificationsToken', payload);
            await moduleStorage.update(state);
            return response;
          });
      }
    },

    async deactivateNotificationsToken({ commit, state }) {
      const fcmToken = state.notificationToken ?? false;

      if (fcmToken) {
        return await notificationsService
          .deactivateNotificationsToken({ token: fcmToken })
          .then(async (response) => {
            commit('setNotificationsToken', '');
            await moduleStorage.update(state);
            return response;
          });
      }
    },

    async updatePassword(context, payload) {
      return await profileService.updateChangePassword(payload).then(async (response) => {
        return response;
      });
    },

    async updateBilling({ commit, state }, payload) {
      return await billingService.updateBilling(payload).then(async (response) => {
        if (response.ok) {
          // Update payment method in profile
          commit('updateProfile', { ...state.profile, paymentMethod: payload.paymentMethod });

          // Remove paymentMethod from payload
          delete payload.paymentMethod;
          commit('updateBilling', { ...state.billing, ...payload });

          // Update localstorage
          await moduleStorage.update(state);
        } else {
          console.log('Unable to update billing');
        }
        return response;
      });
    },

    async requestPayment() {
      return await billingService.requestPayment().then(async (response) => {
        return response;
      });
    },
  },
};

export default module;
