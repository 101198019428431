import { create, ApiResponse, ApiErrorResponse } from 'apisauce';
import { AxiosRequestConfig } from 'axios';

import snakeCaseKeys from 'snakecase-keys';
import camelCaseKeys from 'camelcase-keys';
import { openToast } from '@core-app/utils/helpers';
import store from '@core-app/store';
import * as Sentry from '@sentry/vue';

// Defined in the .env file
const apiUrl = import.meta.env.VITE_APP_API_URL;
const isProd = import.meta.env.VITE_APP_ENV === 'production';

// Define Api instance
const apisauce = create({
  baseURL: apiUrl,
});

// Transform data to snakecase
apisauce.addAsyncRequestTransform(async (request: AxiosRequestConfig) => {
  // Get content-type
  const contentType =
    request.headers &&
    (request.headers['content-type'] ??
      request.headers['Content-type'] ??
      request.headers['content-Type'] ??
      request.headers['Content-Type']);

  // Handle non-multipart data
  if (request.data && !contentType.includes('multipart/form-data')) {
    // if string, convert to object
    request.data = typeof request.data === 'string' ? JSON.parse(request.data) : request.data;
    request.data = snakeCaseKeys(request.data, { deep: true });
  }

  if (request.params && !contentType.includes('multipart')) {
    request.params = snakeCaseKeys(request.params, { deep: true });
  }
});

// Convert data to camelCase before handling
apisauce.addAsyncResponseTransform(async (response) => {
  response.data = camelCaseKeys(response.data, { deep: true });
});

export const useApi = () => {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  const handleResponse = (response: ApiResponse<any>) => {
    // Check error response for non-client errors
    if (!response.ok && response.problem !== 'CLIENT_ERROR') {
      let errorMessage = response.originalError.message;
      let displayError = true;
      const url = response.config?.url ?? '';

      if (response && response.data && response.data.message !== '') {
        errorMessage = response.data.message;
      }

      switch (response.problem) {
        case 'NETWORK_ERROR':
          errorMessage = `${errorMessage}: There is a problem with the network`;
          displayError = false;
          break;
        case 'SERVER_ERROR':
          if (response.status !== 503) {
            errorMessage = `${response.status} Error: ${errorMessage}`;
          }

          // Don't display error popup for /app-config endpoint
          if (response.status === 503 && url.includes('/app-config')) {
            displayError = false;
          }
          break;
        case 'TIMEOUT_ERROR':
          errorMessage = `${errorMessage}: Server response timed out`;
          break;

        case 'CONNECTION_ERROR':
          errorMessage = `${errorMessage}: There is an issue with your internet connection`;
          break;

        default:
        case 'UNKNOWN_ERROR':
          errorMessage = `Error: ${errorMessage}`;
          break;
      }

      if (displayError) {
        openToast.error(errorMessage);
      }

      if (!isProd) {
        console.log(
          '@core-app/services/api.ts::handleResponse()',
          response.ok,
          response.problem,
          response.originalError,
          errorMessage
        );
      }
    }

    // Handle CLIENT_ERRORS
    const excludedRoutes = [
      '/login',
      '/register/step1',
      '/register/step2',
      '/register/step3',
      '/register/step4',
      '/register/step5',
      '/register/step6',
      '/forgot-password',
    ];

    if (
      !response.ok &&
      response.problem === 'CLIENT_ERROR' &&
      excludedRoutes.includes(response.config?.url ?? '')
    ) {
      // Eligible status Codes to attempt to refresh the accessToken
      // 400 - Bad Request
      // 401 - Unauthorized
      // 403 - Forbidden
      // 422 - Unprocessable Entity
      // const errorStatusCodes = [401, 403];

      const noToastStatusCodes = [400, 401, 403, 422];

      if (response.status && !noToastStatusCodes.includes(response.status)) {
        let errorMessage = response.originalError.message;

        if (response.data.message !== '') {
          errorMessage = response.data.message;
        }

        switch (response.status) {
          case 401:
            errorMessage = '401 Error: Unauthorized';
            break;

          case 403:
            errorMessage = '403 Error: Forbidden';
            break;

          default:
            errorMessage = `${errorMessage}`;
            break;
        }
        openToast.error(errorMessage);
      }
    }

    // Excluded Routes for Sentry
    const sentryExcludedRoutes = [
      '/login',
      '/register/step1',
      '/register/step2',
      '/register/step3',
      '/register/step4',
      '/register/step5',
      '/register/step6',
      '/forgot-password',
      '/verify-token',
    ];

    // Capture Axios Errors in Sentry
    if (isProd && !response.ok && !sentryExcludedRoutes.includes(response.config?.url ?? '')) {
      const originalError = response.originalError;
      originalError.message += `: ${response.config?.url ?? ''}`;

      Sentry.captureException(originalError, {
        extra: {
          axiosData: response.config,
        },
      });
    }

    if (!isProd) {
      // Show response in console for testing
      console.log('response for endpoint:', response.config?.url, response);
    }

    return response;
  };

  const setAuthToken = (token: string) => {
    apisauce.setHeader('Authorization', `Bearer ${token}`);
  };

  const removeAuthToken = () => {
    apisauce.deleteHeader('Authorization');
  };

  const getNetworkStatus = async () => {
    const networkStatus = store.state.appModule?.networkStatus;

    if (networkStatus && !networkStatus.connected) {
      // openToast.error('Error: No Internet Connection');
      return false;
    }

    return true;
  };

  const errorResponse = {
    ok: false,
    problem: 'CANCEL_ERROR',
    originalError: {},
    data: {},
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  } as ApiErrorResponse<any>;

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  const get = async (resource: string, params?: any) => {
    const status = await getNetworkStatus();
    return status ? apisauce.get(resource, params).then(handleResponse) : errorResponse;
  };
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  const post = async (resource: string, data?: any) => {
    const status = await getNetworkStatus();
    return status ? apisauce.post(resource, data).then(handleResponse) : errorResponse;
  };

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  const postUpload = async (resource: string, data?: any) => {
    const status = await getNetworkStatus();

    // Transform request manually instead of using the AsyncRequestTransformer()
    data = snakeCaseKeys(data, { deep: false });

    // Convert to FormData
    const formData: FormData = new FormData();
    for (const key in data) {
      if (Array.isArray(data[key])) {
        data[key].forEach((value: string, index: number) => {
          formData.append(`${key}[${index}]`, value);
        });
      } else {
        formData.append(key, data[key]);
      }
    }

    // Set content-type to multipart/form-data
    const headers = {
      'Content-Type': 'multipart/form-data',
    };

    return status
      ? apisauce.post(resource, formData, { headers }).then(handleResponse)
      : errorResponse;
  };

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  const put = async (resource: string, data?: any) => {
    const status = await getNetworkStatus();
    return status ? apisauce.put(resource, data).then(handleResponse) : errorResponse;
  };
  const remove = async (resource: string) => {
    const status = await getNetworkStatus();
    return status ? apisauce.delete(resource).then(handleResponse) : errorResponse;
  };

  return { get, post, postUpload, put, remove, setAuthToken, removeAuthToken };
};
