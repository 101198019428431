import { Module } from 'vuex';
import { RootState } from '@core-app/store';
import { getData, setData, clearStorage } from '@core-app/utils/storage';
import { useAuthService } from '@core-app/services/auth.service';
import { Analytics, PushHelper } from '@core-app/utils/plugins';
import { useToggleDark } from '@core-app/utils/helpers';
import router from '@core-app/router';

interface Question {
  questionId: number;
  answer: object | string;
}

const initialRegistrationForm = {
  firstName: '',
  lastName: '',
  company: '',
  email: '',
  phone: '',
  password: '',
  passwordConfirmation: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  zipCode: '',
  country: '',
  questions: [] as Question[],
  signupIp: '',
  referralId: '',
};

export type RegistrationForm = typeof initialRegistrationForm;

// Setup initial states
const initialState = {
  user: {},
  token: '',
  rememberMe: false,
  resetPasswordToken: '',
  registrationForm: initialRegistrationForm,
};
export type InitialState = typeof initialState;

// Get API Service
const authService = useAuthService();

// Module storage functions
const moduleStorage = {
  moduleName: 'authModule',
  fetch: async function () {
    return await getData(this.moduleName);
  },
  update: async function (state: typeof initialState) {
    await setData(this.moduleName, state);
  },
};

const module: Module<InitialState, RootState> = {
  namespaced: true,
  state: initialState,
  getters: {
    authenticated(state) {
      return state.token !== '';
    },
  },
  mutations: {
    updateState(state, payload) {
      Object.assign(state, payload);
    },

    updateUser(state, user) {
      state.user = user;
    },

    updateResetPasswordToken(state, payload) {
      state.resetPasswordToken = payload;
    },

    updateRegistrationForm(state, payload) {
      state.registrationForm = { ...state.registrationForm, ...payload };
    },

    loginSuccess(state, payload) {
      const { user, token, rememberMe } = payload;

      state.user = user;

      // Save token if remember me is set
      if (rememberMe) {
        state.token = token;
        state.rememberMe = rememberMe;
      } else {
        state.token = '';
        state.rememberMe = false;
      }
    },

    logout(state) {
      state.user = {};
      state.token = '';
      state.rememberMe = false;
      state.registrationForm = initialRegistrationForm;
    },
  },

  actions: {
    async updateStateFromStorage({ commit }) {
      return moduleStorage.fetch().then((data) => {
        if (data) {
          commit('updateState', data);
        }
      });
    },

    async setResetPasswordToken({ commit, state }, payload) {
      commit('updateResetPasswordToken', payload);
      await moduleStorage.update(state);
    },

    async setRegistrationForm({ commit, state }, payload) {
      commit('updateRegistrationForm', payload);
      await moduleStorage.update(state);
    },

    async verifyToken({ commit, dispatch, state }) {
      if (state.rememberMe && state.token !== '') {
        return await authService.verifyToken(state.token).then(async (response) => {
          if (response.ok) {
            const data = response?.data?.data ?? {};

            // Check to see if using employee login
            if (data.user.userType === 'employee') {
              dispatch('logout');
              await router.replace('/login');
              return;
            }

            // Update user
            const user = data.user;
            commit('updateUser', user);

            // Set roles
            if (user && user.rolesList) {
              await dispatch('permissionsModule/updateRoles', user.rolesList, { root: true });
            }

            // Set permisssions
            if (user && user.permissionsList) {
              await dispatch('permissionsModule/updatePermissions', user.permissionsList, {
                root: true,
              });
            }

            // Set authenticated config
            if (data.authenticatedConfig) {
              await commit('appModule/setAuthenticatedConfig', data.authenticatedConfig, {
                root: true,
              });
            }

            // Set user ID for analytics
            Analytics.setUser(user);

            // Start loading data
            await dispatch('appModule/loadAppData', null, { root: true });

            // Set onboarding viewed
            const onboardingViewed = user.options.onboardingViewed;
            if (onboardingViewed !== undefined) {
              dispatch('profileModule/setOnboardingViewed', onboardingViewed, {
                root: true,
              });
            }

            // Update localstorage
            await moduleStorage.update(state);
          } else {
            // If 401 Unauthorized, then dispatch logout routine
            if (response.status === 401) {
              dispatch('logout');
            }

            // If the response/token is not OK for whatever reason, then navigate to the login
            await router.replace('/login');
          }

          return response;
        });
      }
    },

    async login({ commit, dispatch, state }, payload) {
      const user = {
        username: payload.username,
        password: payload.password,
      };

      return await authService.login(user).then(async (response) => {
        if (response.ok) {
          // Save data to store
          const data = response?.data?.data ?? {};

          // Check to see if using employee login
          if (data.user.userType === 'employee') {
            // Do logout routine
            dispatch('logout');

            // Modify response for login
            /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
            const newResponse: { [key: string]: any } = response;
            newResponse.ok = false;
            newResponse.data.message =
              'Employee Accounts are not available in the app at this time.';

            return newResponse;
          }

          // Set remember me
          data.rememberMe = payload.rememberMe;
          commit('loginSuccess', data);

          // Extract user
          const user = data.user;
          commit('updateUser', user);

          // Set roles
          if (user && user.rolesList) {
            await dispatch('permissionsModule/updateRoles', user.rolesList, { root: true });
          }

          // Set permisssions
          if (user && user.permissionsList) {
            await dispatch('permissionsModule/updatePermissions', user.permissionsList, {
              root: true,
            });
          }

          // Set authenticated config
          if (data.authenticatedConfig) {
            await commit('appModule/setAuthenticatedConfig', data.authenticatedConfig, {
              root: true,
            });
          }

          // Set user ID for analytics
          Analytics.setUser(user);

          // Set token in headers
          authService.api.setAuthToken(data.token);

          // Start loading data after auth token gets set
          await dispatch('appModule/loadAppData', null, { root: true });

          // Set onboarding viewed
          const onboardingViewed = data.user.options.onboardingViewed;
          if (onboardingViewed !== undefined) {
            dispatch('profileModule/setOnboardingViewed', onboardingViewed, {
              root: true,
            });
          }

          // Check dark mode and set it before redirecting to the /app
          const { setDarkMode } = useToggleDark();
          setDarkMode();

          // Update localstorage
          await moduleStorage.update(state);

          // Navigate to the default /app page
          await router.push('/app');
        } else {
          dispatch('logout');
        }

        return response;
      });
    },

    async logout({ commit, state, dispatch }) {
      // Clear the badge count
      PushHelper.setBadgeCount(0);

      // Deactivate the current token
      await dispatch('profileModule/deactivateNotificationsToken', null, { root: true });

      // Clear authModule state
      commit('logout');

      // Remove auth token from axios interceptors
      authService.api.removeAuthToken();

      // Hide Intercom Launcher
      Analytics.hideIntercomLauncher();

      // Clear local storage
      await clearStorage();

      // Update localstorage
      await moduleStorage.update(state);

      // Go back to /login page
      await router.replace('/login');
    },

    async deactivateAccount({ dispatch }) {
      return await authService.deactivateAccount().then(async (response) => {
        // Dispatch the logout action routine
        dispatch('logout');
        return response;
      });
    },

    async checkUniqueEmail(context, payload) {
      return await authService.checkUniqueEmail(payload).then(async (response) => {
        return response;
      });
    },

    async register({ commit, state }) {
      // // Retrieve IP Address of user
      // const signupIp = await getIpAddress();

      // // Update registration info in store
      // commit('updateRegistrationForm', { signupIp });

      return await authService.register(state.registrationForm).then(async (response) => {
        if (response.ok) {
          // Clear registration data
          commit('updateRegistrationForm', initialRegistrationForm);

          // Update localstorage
          await moduleStorage.update(state);

          // Navigate to the final page
          // await router.push('/register/step6');
        }

        return response;
      });
    },

    async forgotPassword(context, payload) {
      return await authService.forgotPassword(payload).then(async (response) => {
        return response;
      });
    },

    async resetPassword({ state }, payload) {
      const data = {
        ...payload,
        token: state.resetPasswordToken,
      };

      return await authService.resetPassword(data).then(async (response) => {
        return response;
      });
    },
  },
};

export default module;
