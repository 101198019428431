import { useApi } from './api';

export interface NotificationData {
  path: string;
  isClickable: string;
}

export interface Notification {
  id: string;
  readAt: string;
  icon: string;
  body: string;
  badge: number;
  image: string;
  sound: string;
  title: string;
  topic: string;
  date: string;
  dateTime: string;
  timeAgo: string;
  data: NotificationData;
}

export interface UserOptionsPushNotifications {
  allNotifications: boolean;
  announcements: boolean;
  billing: boolean;
  conversions: boolean;
  directMessages: boolean;
  groupChat: boolean;
  offers: boolean;
  support: boolean;
  conversionSound: string;
  conversionTemplateId: number;
}
export interface UserOptionsEmailNotifications {
  allEmails: boolean;
  billingRequestPayment: boolean;
  billingInvoiceGenerated: boolean;
  billingPaymentSent: boolean;
}

export const useNotificationsService = () => {
  const api = useApi();

  const getNotifications = async (payload: { page: number }) => {
    return await api.get('/notifications', payload);
  };

  const getSocialFeedNotifications = async (payload: { page: number }) => {
    return await api.get('/notifications/social-feed', payload);
  };

  const updateNotifications = async (payload: { id: string[]; action: string }) => {
    return await api.post('/notifications', payload);
  };

  const markAllAsRead = async () => {
    return await api.post('/notifications/all/mark-as-read');
  };

  const updateNotificationOptions = async (payload: UserOptionsPushNotifications) => {
    return await api.post('/notifications/options', payload);
  };

  const updateNotificationsToken = async (payload: { token: string }) => {
    return await api.post('/notifications/token/activate', payload);
  };

  const deactivateNotificationsToken = async (payload: { token: string }) => {
    return await api.post('/notifications/token/deactivate', payload);
  };

  return {
    api,
    getNotifications,
    getSocialFeedNotifications,
    updateNotifications,
    markAllAsRead,
    updateNotificationOptions,
    updateNotificationsToken,
    deactivateNotificationsToken,
  };
};
