import { Preferences, GetResult } from '@capacitor/preferences';

export async function get(key: string): Promise<GetResult> {
  return await Preferences.get({ key });
}

export async function set(key: string, value: string): Promise<void> {
  return await Preferences.set({ key, value });
}

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export async function getData(key: string): Promise<any> {
  const { value } = await Preferences.get({ key });
  return value ? JSON.parse(value) : {};
}

export async function setData(key: string, data: object): Promise<void> {
  await Preferences.set({ key, value: JSON.stringify(data) });

  return await Preferences.set({ key, value: JSON.stringify(data) });
}

export async function clearStorage() {
  await Preferences.clear();
}
