import { RouteRecordRaw } from 'vue-router';
import { Analytics } from '@core-app/utils/plugins';

const routes: Array<RouteRecordRaw> = [
  {
    name: 'Root/AppLoading',
    path: '/',
    component: () => import('@core-app/AppLoading.vue'),
    meta: { publicRoute: true },
  },
  {
    name: 'Auth/Welcome',
    path: '/welcome',
    component: () => import('@core-app/pages/core/auth/WelcomeV2.vue'),
    meta: { publicRoute: true },
  },
  {
    name: 'Auth/Login',
    path: '/login',
    component: () => import('@core-app/pages/core/auth/Login.vue'),
    meta: { publicRoute: true },
  },
  {
    name: 'Auth/ForgotPassword',
    path: '/forgot-password',
    component: () => import('@core-app/pages/core/auth/ForgotPassword.vue'),
    meta: { publicRoute: true },
  },
  {
    name: 'Auth/ResetPassword',
    path: '/reset-password',
    component: () => import('@core-app/pages/core/auth/ResetPassword.vue'),
    meta: { publicRoute: true },
  },

  { name: 'Register', path: '/register', redirect: '/register/step1', meta: { publicRoute: true } },
  {
    name: 'Auth/RegisterStep1',
    path: '/register/step1',
    component: () => import('@core-app/pages/tapmob/auth/Signup1.vue'),
    meta: { publicRoute: true },
  },
  {
    name: 'Auth/RegisterStep2',
    path: '/register/step2',
    component: () => import('@core-app/pages/core/auth/Signup2.vue'),
    meta: { publicRoute: true },
  },
  {
    name: 'Auth/RegisterStep3',
    path: '/register/step3',
    component: () => import('@core-app/pages/core/auth/Signup3.vue'),
    meta: { publicRoute: true },
  },
  {
    name: 'Auth/RegisterStep4',
    path: '/register/step4',
    component: () => import('@core-app/pages/core/auth/Signup4.vue'),
    meta: { publicRoute: true },
  },
  {
    name: 'Auth/RegisterStep5',
    path: '/register/step5',
    component: () => import('@core-app/pages/core/auth/Signup5.vue'),
    meta: { publicRoute: true },
  },
  {
    name: 'Auth/RegisterStep6',
    path: '/register/step6',
    component: () => import('@core-app/pages/core/auth/Signup6.vue'),
    meta: { publicRoute: true },
  },

  {
    name: 'App',
    path: '/app',
    component: () => import('@core-app/components/layout/FooterTabs.vue'),
    children: [
      { name: 'App/Redirect', path: '', redirect: '/app/dashboard' },
      // { name: 'App/Redirect', path: '', redirect: '/app/offers' },
      {
        name: 'App/Dashboard',
        path: 'dashboard',
        component: () => import('@core-app/pages/core/dashboard/Dashboard.vue'),
        beforeEnter: () => {
          Analytics.displayIntercomLauncher();
        },
      },
      {
        name: 'App/Dashboard/Notifications',
        path: 'dashboard/notifications',
        component: () => import('@core-app/pages/core/dashboard/Notifications.vue'),
      },
      {
        name: 'App/Teams/TeamManager',
        path: 'teams/manager',
        component: () => import('@core-app/pages/core/teams/TeamManager.vue'),
      },
      {
        name: 'App/Stats',
        path: 'stats',
        component: () => import('@core-app/pages/core/stats/Stats.vue'),
      },
      {
        name: 'App/Leaderboard',
        path: 'leaderboard',
        component: () => import('@core-app/pages/core/leaderboard/Leaderboard.vue'),
      },
      {
        name: 'App/Offers',
        path: 'offers',
        component: () => import('@core-app/pages/core/offers/Offers.vue'),
      },
      {
        name: 'App/Offers/Filters',
        path: 'offers/filters',
        component: () => import('@core-app/pages/core/offers/OffersFilters.vue'),
      },
      {
        name: 'App/Offers/Detail',
        path: 'offers/:offerId',
        component: () => import('@core-app/pages/core/offers/OfferDetail.vue'),
        props: true,
      },
      {
        name: 'App/Offers/Creatives',
        path: 'offers/creatives/:offerId',
        component: () => import('@core-app/pages/core/offers/OfferCreatives.vue'),
        props: true,
      },
      {
        name: 'App/ContentLockers',
        path: 'content-lockers',
        component: () => import('@core-app/pages/core/content-lockers/ContentLockerList.vue'),
      },
      {
        name: 'App/ContentLockers/Create',
        path: 'content-lockers/create',
        component: () => import('@core-app/pages/core/content-lockers/ContentLockerEdit.vue'),
      },
      {
        name: 'App/ContentLockers/Edit',
        path: 'content-lockers/edit',
        component: () => import('@core-app/pages/core/content-lockers/ContentLockerEdit.vue'),
      },
      {
        name: 'App/Profile',
        path: 'profile',
        component: () => import('@core-app/pages/core/profile/Profile.vue'),
      },
      {
        name: 'App/Profile/W9Form',
        path: 'profile/w9form',
        component: () => import('@core-app/pages/core/profile/ProfileW9Form.vue'),
      },
      {
        name: 'App/Profile/History',
        path: 'profile/history',
        component: () => import('@core-app/pages/core/profile/invoices/ProfileHistoryListV2.vue'),
      },
      {
        name: 'App/Profile/History/InvoiceId',
        path: 'profile/history/:invoiceId',
        component: () => import('@core-app/pages/core/profile/invoices/ProfileHistoryInvoice.vue'),
        props: true,
      },
      {
        name: 'App/Profile/Payment',
        path: 'profile/payment',
        // component: () => import('@core-app/pages/tapmob/profile/ProfilePayment.vue'),
        component: () => import('@core-app/pages/core/profile/payment/ProfilePayment.vue'),
      },
      {
        name: 'App/Profile/Payment/Add/Direct-Deposit',
        path: 'profile/payment/add/direct-deposit',
        component: () =>
          import('@core-app/pages/core/profile/payment/ProfilePaymentAddMethodDirectDeposit.vue'),
      },
      {
        name: 'App/Profile/Payment/Add/Other',
        path: 'profile/payment/add/other',
        component: () =>
          import('@core-app/pages/core/profile/payment/ProfilePaymentAddMethodOther.vue'),
      },
      {
        name: 'App/Profile/Payment/Add/Paypal',
        path: 'profile/payment/add/paypal',
        component: () =>
          import('@core-app/pages/core/profile/payment/ProfilePaymentAddMethodPaypal.vue'),
      },
      {
        name: 'App/Profile/Payment/Add/Wire',
        path: 'profile/payment/add/wire',
        component: () =>
          import('@core-app/pages/core/profile/payment/ProfilePaymentAddMethodWire.vue'),
      },
      {
        name: 'App/Profile/Settings',
        path: 'profile/settings',
        component: () => import('@core-app/pages/core/profile/settings/ProfileSettings.vue'),
      },
      {
        name: 'App/Profile/ChangePassword',
        path: 'profile/settings/change-password',
        component: () =>
          import('@core-app/pages/core/profile/settings/ProfileSettingsChangePassword.vue'),
      },
      {
        name: 'App/Profile/Settings/Account',
        path: 'profile/settings/account',
        component: () => import('@core-app/pages/core/profile/settings/ProfileSettingsAccount.vue'),
      },
      {
        name: 'App/Profile/Settings/EditProfile',
        path: 'profile/settings/edit',
        component: () =>
          import('@core-app/pages/core/profile/settings/ProfileSettingsEditProfile.vue'),
      },
      {
        name: 'App/Profile/Settings/Notifications',
        path: 'profile/settings/notifications',
        component: () =>
          import('@core-app/pages/core/profile/settings/ProfileSettingsNotification.vue'),
      },
      {
        name: 'App/Profile/Settings/Faq',
        path: 'profile/settings/faq',
        component: () => import('@core-app/pages/core/profile/settings/ProfileSettingsFaq.vue'),
      },
    ],
  },
  {
    name: 'CatchAll',
    path: '/:catchAll(.*)',
    component: () => import('@core-app/components/layout/FooterTabs.vue'),
    children: [
      {
        name: 'CatchAll/Error404',
        path: '',
        component: () => import('@core-app/pages/core/Error404.vue'),
      },
    ],
  },
];

export default routes;
