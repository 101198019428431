import { Module } from 'vuex';
import { RootState } from '@core-app/store';
import { getData, setData } from '@core-app/utils/storage';
import { useLeaderboardService } from '@core-app/services/leaderboard.service';

export type LeaderboardTabs = 'thisMonth' | 'lastMonth';

export interface LeaderboardEntry {
  affiliateId: number;
  affiliateUsername: string;
  avatar: { [key: string]: string };
  rank: number | string;
  payout: number | string;
  conversions: number | string;
}

export interface LeaderboardMonth {
  currentEntry: LeaderboardEntry;
  entries: LeaderboardEntry[];
}

export interface Leaderboard {
  enabled: boolean;
  config: {
    pointsField: string;
    pointsDisplayName: string;
    displayPointsColumn: boolean;
    displayAvatars: boolean;
  };
  minimumAmount: number | string;
  thisMonth: LeaderboardMonth;
  lastMonth: LeaderboardMonth;
}

// Setup initial states
const initialState = {
  leaderboardNetwork: {} as Leaderboard,
  leaderboardTeams: {} as Leaderboard,
  leaderboardReferrer: {} as Leaderboard,
};
export type InitialState = typeof initialState;

// Get API Service
const leaderboardService = useLeaderboardService();

// Module storage functions
const moduleStorage = {
  moduleName: 'leaderboardModule',
  fetch: async function () {
    return await getData(this.moduleName);
  },
  update: async function (state: typeof initialState) {
    await setData(this.moduleName, state);
  },
};

// Setup state module
const module: Module<InitialState, RootState> = {
  namespaced: true,
  state: initialState,
  getters: {},
  mutations: {
    updateState(state, data) {
      Object.assign(state, data);
    },
    updateLeaderboardNetwork(state, payload) {
      state.leaderboardNetwork = payload;
    },
    updateLeaderboardTeams(state, payload) {
      state.leaderboardTeams = payload;
    },
    updateLeaderboardReferrer(state, payload) {
      state.leaderboardReferrer = payload;
    },
  },

  actions: {
    async updateStateFromStorage({ commit }) {
      return moduleStorage.fetch().then((data) => {
        if (data) {
          commit('updateState', data);
        }
      });
    },

    async fetchLeaderboardAll({ commit, state }) {
      return await leaderboardService.getLeaderboardAll().then(async (response) => {
        if (response.ok) {
          // Save data to store
          const data = response?.data?.data ?? {};

          const leaderboardNetwork = data?.leaderboardNetwork;
          const leaderboardTeams = data?.leaderboardTeams;
          const leaderboardReferrer = data?.leaderboardReferrer;

          if (leaderboardNetwork?.lastMonth && leaderboardNetwork?.thisMonth) {
            const leaderboardNetworkData = {
              enabled: leaderboardNetwork.enabled,
              config: leaderboardNetwork.config || {
                pointsField: 'conversions',
                pointsDisplayName: 'Conversions',
              },
              minimumAmount: leaderboardNetwork.minimumAmount,
              lastMonth: leaderboardNetwork.lastMonth,
              thisMonth: leaderboardNetwork.thisMonth,
            };
            commit('updateLeaderboardNetwork', leaderboardNetworkData);
          }
          if (leaderboardTeams?.lastMonth && leaderboardTeams?.thisMonth) {
            const leaderboardTeamsData = {
              enabled: leaderboardTeams.enabled,
              config: leaderboardTeams.config || {
                pointsField: 'conversions',
                pointsDisplayName: 'Conversions',
              },
              minimumAmount: leaderboardTeams.minimumAmount,
              lastMonth: leaderboardTeams.lastMonth,
              thisMonth: leaderboardTeams.thisMonth,
            };
            commit('updateLeaderboardTeams', leaderboardTeamsData);
          }
          if (leaderboardReferrer?.lastMonth && leaderboardReferrer?.thisMonth) {
            const leaderboardReferrerData = {
              enabled: leaderboardReferrer.enabled,
              config: leaderboardReferrer.config || {
                pointsField: 'conversions',
                pointsDisplayName: 'Conversions',
              },
              minimumAmount: leaderboardReferrer.minimumAmount,
              lastMonth: leaderboardReferrer.lastMonth,
              thisMonth: leaderboardReferrer.thisMonth,
            };
            commit('updateLeaderboardReferrer', leaderboardReferrerData);
          }

          // Update localstorage
          await moduleStorage.update(state);
        } else {
          console.log('Unable to fetch leaderboard');
        }
        return response;
      });
    },
  },
};

export default module;
