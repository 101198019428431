import { useApi } from './api';

export const useTeamsService = () => {
  const api = useApi();

  const getTeamStats = async () => {
    return await api.get('/teams/stats');
  };

  return { api, getTeamStats };
};
