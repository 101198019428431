import { useApi } from './api';

export interface CreativeInterface {
  fileName: string;
  mimeType: string;
  originalUrl: string;
  thumbnailUrl: string;
}

export const useOfferService = () => {
  const api = useApi();

  const getOffers = async () => {
    // const params = {
    //   categoryIds: '2',
    // };
    // return await api.get('/offers', params);

    return await api.get('/offers');
  };

  const createOfferApproval = async (payload: {
    offerId: number;
    // affiliateId: number;
    approvalNotes: string;
  }) => {
    return await api.post('/offers/approvals', payload);
  };

  const createOfferCustomLink = async (payload: {
    domainId: number;
    offerId: number;
    customLink: string;
  }) => {
    return await api.post('/offers/custom-link', payload);
  };

  const deleteOfferCustomLink = async (payload: { customLinkId: number }) => {
    return await api.remove(`/offers/custom-link/${payload.customLinkId}`);
  };

  return { api, getOffers, createOfferApproval, createOfferCustomLink, deleteOfferCustomLink };
};
