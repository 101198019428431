import { startCase, toLower } from 'lodash';
import numeral from 'numeral';
import moment from 'moment';

import snakecaseKeys from 'snakecase-keys';
import camelcaseKeys from 'camelcase-keys';

import { camelCase, capitalCase, noCase } from 'change-case';

const filters = {
  convertToLocalTime(datetime: string) {
    return moment.utc(datetime).local().format('llll');
  },
  convertToLocalDate(datetime: string) {
    return moment.utc(datetime).local().format('ll');
  },

  capitalize(value: string) {
    // return _.startCase(_.toLower(value));
    return startCase(toLower(value));
  },
  formatCurrencyBasic(value: number) {
    return numeral(value).format('$0,0');
  },
  formatCurrency(value: number) {
    return numeral(value).format('$0,0.00');
  },
  formatRank(value: number) {
    return numeral(value).format('00');
  },
  formatNumber(value: number) {
    return numeral(value).format('0,0');
  },
  formatPercentageBasic(value: number) {
    return numeral(value).format('0%');
  },
  formatPercentagePrecise(value: number) {
    return numeral(value).format('0.00%');
  },
  formatPercentageChange(value: number) {
    return numeral(value).format('+0.00%');
  },
  calcPercentageChange(originalValue: number, newValue: number) {
    if (originalValue === 0) {
      return 0;
    }
    return (newValue - originalValue) / originalValue;
  },
  formatBytes(value: number) {
    return numeral(value).format('0.00b');
  },
  toNumber(value: string) {
    return numeral(value).value();
  },

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  toSnakecaseKeys(value: any) {
    return snakecaseKeys(value);
  },

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  toCamelcaseKeys(value: any) {
    return camelcaseKeys(value);
  },

  toCamelcase(value: string) {
    return camelCase(value);
  },
  toCapitalCase(value: string) {
    return capitalCase(value);
  },
  toCapitalCaseWithSpaces(value: string) {
    return capitalCase(noCase(value));
  },

  truncateText(value: string, length: number) {
    return value.length > length ? `${value.substring(0, length)}...` : value;
  },
};

export { filters };
