<template>
  <ion-grid class="update-modal">
    <ion-row>
      <ion-col>
        <transition name="default-fade" mode="out-in">
          <div>
            <h3 class="ion-text-center">
              <!-- <ion-spinner /> -->
              Updating App...
            </h3>

            <div
              class="update-notes"
              v-if="modalData.updateNotes !== ''"
              v-html="sanitizeHtml(modalData.updateNotes)"
            />

            <p class="progress-text">
              <ion-spinner name="lines-small" />
              {{ modalData.progressText }}
            </p>

            <ion-progress-bar color="primary" :value="modalData.progressPercentage" />
          </div>
        </transition>
      </ion-col>
    </ion-row>
  </ion-grid>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import sanitizeHtml from 'sanitize-html';

interface ModalData {
  updateNotes: string;
  progressText: string;
  progressPercentage: number;
}

export default defineComponent({
  name: 'UpdateModal',
  props: {
    modalData: {
      type: Object as PropType<ModalData>,
      default: () => ({
        updateNotes: '',
        progressText: 'initializing...',
        progressPercentage: 0,
      }),
    },
  },
  setup() {
    return {
      sanitizeHtml,
    };
  },
});
</script>

<style lang="scss" scoped>
ion-grid.update-modal {
  h3 {
    ion-spinner {
      margin-right: 8px;
    }
    margin: 10px 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  div.update-notes {
    margin: 16px 0;
  }
  p.progress-text {
    display: flex;
    align-items: center;
    justify-content: center;

    text-align: center;
    margin: 0;
    margin-bottom: 8px;
  }
}
</style>
