import { useApi } from './api';

export const useStatsService = () => {
  const api = useApi();

  // const getStats = async () => {
  //   const params = {
  //     dateStart: '2020-12-01',
  //     dateEnd: '2020-12-24',
  //     fields: ['date', 'clicks', 'conversions', 'payout'],
  //   };
  //   return await api.get('/stats', params);
  // };

  const getStatsToday = async () => {
    const params = {};
    return await api.get('/stats/today', params);
  };

  const getStatsThisWeek = async () => {
    const params = {};
    return await api.get('/stats/this-week', params);
  };

  const getStatsThisMonth = async () => {
    const params = {};
    return await api.get('/stats/this-month', params);
  };

  const getStatsThisYear = async () => {
    const params = {};
    return await api.get('/stats/this-year', params);
  };

  return {
    api,
    // getStats,
    getStatsToday,
    getStatsThisWeek,
    getStatsThisMonth,
    getStatsThisYear,
  };
};
