import { Module } from 'vuex';
import { RootState } from '@core-app/store';
import { getData, setData } from '@core-app/utils/storage';
import { useDashboardService } from '@core-app/services/dashboard.service';
import { useNotificationsService } from '@core-app/services/notifications.service';

export interface RecentActivityItem {
  browserDisplayName: string;
  cityName: string;
  countryCode: string;
  datetime: Date;
  deviceBrand: string;
  deviceModel: string;
  deviceOs: string;
  offerId: number;
  offerName: string;
  offerPayout: string;
  offerStatus: string;
  offerThumbnailUrl: string;
  payout: string;
  regionCode: string;
  regionName: string;
  source: string;
  timeAgo: string;
}

export type RecentActivity = RecentActivityItem[];

export interface TopCampaignOffer {
  cvr: number | string;
  epc: number | string;
  offerId: number;
  offerName: string;
  offerPayout: number | string;
  offerStatus: string;
  offerThumbnailUrl: URL;
  rank: number;
}
export type TopCampaigns = TopCampaignOffer[];

export interface DashboardConfig {
  messageBox: {
    background: string;
    content: string;
    enabled: boolean;
  };
}

// Setup initial states
const initialState = {
  loading: {
    stats: false,
    recentActivity: false,
    topCampaigns: false,
    socialFeed: false,
    config: false,
  },
  dashboard: {
    stats: {
      payoutToday: 0 as number | string,
      payoutHourly: {
        lastHourPayout: 0 as number | string,
        thisHourPayout: 0 as number | string,
        percentChange: 0 as number | string,
      },
      userRank: 0,
      referrals: 0,
      referralCommission: 0,
    },
    recentActivity: [] as RecentActivity,
    topCampaigns: [] as TopCampaigns,
    config: {
      messageBox: {
        background: '',
        content: '',
        enabled: false,
      },
    } as DashboardConfig,
  },
  socialFeedNotifications: [],
};
export type InitialState = typeof initialState;

// Get API Service
const dashboardService = useDashboardService();
const notificationsService = useNotificationsService();

// Module storage functions
const moduleStorage = {
  moduleName: 'dashboardModule',
  fetch: async function () {
    return await getData(this.moduleName);
  },
  update: async function (state: typeof initialState) {
    await setData(this.moduleName, state);
  },
};

// Setup state module
const module: Module<InitialState, RootState> = {
  namespaced: true,
  state: initialState,
  getters: {},
  mutations: {
    updateState(state, data) {
      Object.assign(state, data);
    },
    updateDashboard(state, payload) {
      state.dashboard = payload;
    },
    mutateLoading(state, payload: { key: string; status: boolean }) {
      if (payload.key === 'all') {
        state.loading.stats = payload.status;
        state.loading.recentActivity = payload.status;
        state.loading.topCampaigns = payload.status;
        state.loading.socialFeed = payload.status;
        state.loading.config = payload.status;
      }
      if (payload.key === 'stats') {
        state.loading.stats = payload.status;
      }
      if (payload.key === 'recentActivity') {
        state.loading.recentActivity = payload.status;
      }
      if (payload.key === 'topCampaigns') {
        state.loading.topCampaigns = payload.status;
      }
      if (payload.key === 'socialFeed') {
        state.loading.socialFeed = payload.status;
      }
      if (payload.key === 'config') {
        state.loading.config = payload.status;
      }
    },
    mutateDashboardStats(state, payload) {
      state.dashboard.stats = payload;
    },
    mutateDashboardStatsPayoutToday(state, payload) {
      state.dashboard.stats.payoutToday = payload;
    },
    mutateDashboardTopCampaigns(state, payload) {
      state.dashboard.topCampaigns = payload;
    },
    mutateDashboardRecentActivity(state, payload) {
      state.dashboard.recentActivity = payload;
    },
    mutateDashboardConfig(state, payload) {
      state.dashboard.config = payload;
    },
    setSocialFeedNotifications(state, payload) {
      state.socialFeedNotifications = payload;
    },
  },

  actions: {
    async updateStateFromStorage({ commit }) {
      return moduleStorage.fetch().then((data) => {
        if (data) {
          commit('updateState', data);
        }
      });
    },
    async fetchDashboardStats({ commit, state }) {
      commit('mutateLoading', { key: 'stats', status: true });

      return await dashboardService
        .getDashboardStats()
        .then(async (response) => {
          if (response.ok) {
            // Save data to store
            const data = response?.data?.data ?? {};

            if (data) {
              commit('mutateDashboardStats', data);
            }

            // Update localstorage
            await moduleStorage.update(state);
          } else {
            console.log('Unable to fetch leaderboard');
          }
          return response;
        })
        .finally(() => {
          commit('mutateLoading', { key: 'stats', status: false });
        });
    },
    async fetchDashboardTopCampaigns({ commit, state }) {
      commit('mutateLoading', { key: 'topCampaigns', status: true });
      return await dashboardService
        .getDashboardTopCampaigns()
        .then(async (response) => {
          if (response.ok) {
            // Save data to store
            const data = response?.data?.data ?? {};

            if (data?.topCampaigns) {
              commit('mutateDashboardTopCampaigns', data.topCampaigns);
            }

            // Update localstorage
            await moduleStorage.update(state);
          } else {
            console.log('Unable to fetch leaderboard');
          }
          return response;
        })
        .finally(() => {
          commit('mutateLoading', { key: 'topCampaigns', status: false });
        });
    },
    async fetchDashboardRecentActivity({ commit, state }) {
      commit('mutateLoading', { key: 'recentActivity', status: true });
      return await dashboardService
        .getDashboardRecentActivity()
        .then(async (response) => {
          if (response.ok) {
            // Save data to store
            const data = response?.data?.data ?? {};

            if (data?.recentActivity) {
              commit('mutateDashboardRecentActivity', data.recentActivity);
            }

            // Update localstorage
            await moduleStorage.update(state);
          } else {
            console.log('Unable to fetch leaderboard');
          }
          return response;
        })
        .finally(() => {
          commit('mutateLoading', { key: 'recentActivity', status: false });
        });
    },

    async fetchSocialFeedNotifications({ commit }, payload) {
      commit('mutateLoading', { key: 'socialFeed', status: true });
      return await notificationsService
        .getSocialFeedNotifications(payload)
        .then(async (response) => {
          if (response.ok) {
            // Save data to store
            const data = response?.data?.data ?? {};

            // NOTE: Don't save to localstorage,
            // so localstorage hold on to too many old message
            if (data?.notifications) {
              commit('setSocialFeedNotifications', data.notifications);
            }
          } else {
            console.log('Unable to fetch notifications');
          }
          return response;
        })
        .finally(() => {
          commit('mutateLoading', { key: 'socialFeed', status: false });
        });
    },

    async fetchDashboardConfig({ commit }) {
      commit('mutateLoading', { key: 'config', status: true });
      return await dashboardService
        .getDashboardConfig()
        .then(async (response) => {
          if (response.ok) {
            // Save data to store
            const data = response?.data?.data ?? {};

            // NOTE: Don't save to localstorage,
            // so localstorage hold on to too many old message
            if (data?.config) {
              commit('mutateDashboardConfig', data.config);
            }
          } else {
            console.log('Unable to fetch configuration settings');
          }
          return response;
        })
        .finally(() => {
          commit('mutateLoading', { key: 'config', status: false });
        });
    },
  },
};

export default module;
