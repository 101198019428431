<template>
  <ion-app>
    <transition name="default-fade" mode="out-in">
      <ion-router-outlet />
    </transition>
  </ion-app>
</template>

<script lang="ts">
import { IonApp, IonRouterOutlet } from '@ionic/vue';
import { defineComponent } from 'vue';
import { SplashScreen } from '@capacitor/splash-screen';

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet,
  },
  setup() {
    // Remove the capacitor/splash-screen animated gif
    SplashScreen.hide({ fadeOutDuration: 750 });

    return;
  },
});
</script>

<style lang="scss" scoped>
ion-content {
  --background: #19181f;
}

ion-content div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

ion-spinner {
  --color: #ffffff;
}
</style>
