import { reactive } from 'vue';
import { Module } from 'vuex';
import { RootState } from '@core-app/store';
import { getData, setData } from '@core-app/utils/storage';
import { Network, ConnectionType } from '@capacitor/network';
import { useAuthService } from '@core-app/services/auth.service';
import { isDev, useToggleDark, appVersion } from '@core-app/utils/helpers';
import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import semver from 'semver';
import { alertController, modalController } from '@ionic/vue';
import UpdateModal from '@core-app/components/common/UpdateModal.vue';
import { Deploy } from 'cordova-plugin-ionic';

// Setup initial states
const initialState = {
  appLoadingStage: 0,
  appConfig: {
    affReferralPercent: 0.01,
    chatConfigText: 'Join us on Telegram',
    chatConfigLink: 'https://telegram.org',
    minimumPaymentThreshold: 50,

    appUpdateNativeEnabled: false,
    appUpdateNativeAndroidLink: 'https://play.google.com/store',
    appUpdateNativeAndroidVersion: '0.0.0',
    appUpdateNativeIosLink: 'https://www.apple.com/app-store/',
    appUpdateNativeIosVersion: '0.0.0',
    appUpdateNativeNotes: '',

    appUpdateOtaEnabled: false,
    appUpdateOtaSilentInstall: true,
    appUpdateOtaAppVersion: '0.0.0',
    appUpdateOtaNotes: '',

    socialFeedEnable: false,
    supportEmailAddress: '',
  },
  authenticatedConfig: {
    w9Link: 'https://www.irs.gov/pub/irs-pdf/fw9.pdf',
    w8BenLink: 'https://www.irs.gov/pub/irs-pdf/fw8ben.pdf',
    faqLinkUrl: '',
    faqLinkEnabled: false,
    taxformsEmailAddress: '',
    contentLockerEnabled: false,
  },
  networkStatus: {
    connected: false as boolean,
    connectionType: '' as ConnectionType,
  },
};
export type InitialState = typeof initialState;

// Get API Service
const authService = useAuthService();

// Module storage functions
const moduleStorage = {
  moduleName: 'appModule',
  fetch: async function () {
    return await getData(this.moduleName);
  },
  update: async function (state: typeof initialState) {
    await setData(this.moduleName, state);
  },
};

// Setup state module
const module: Module<InitialState, RootState> = {
  namespaced: true,
  state: initialState,
  getters: {},
  mutations: {
    updateState(state, data) {
      Object.assign(state, data);
    },

    setAppLoadingStage(state, payload) {
      state.appLoadingStage = payload;
    },

    setAppConfig(state, appConfig) {
      state.appConfig = appConfig;
    },

    setAuthenticatedConfig(state, payload) {
      state.authenticatedConfig = payload;
    },

    setNetworkStatus(state, payload) {
      state.networkStatus = payload;
    },
  },

  actions: {
    async updateStateFromStorage({ commit }) {
      return moduleStorage.fetch().then((data) => {
        if (data) {
          commit('updateState', data);
        }
      });
    },

    async initializeApp({ dispatch, commit }) {
      // After store gets initialized, set dark mode
      const { setDarkMode } = useToggleDark();
      setDarkMode();

      // Set dashboard loading states to true
      await commit('dashboardModule/mutateLoading', { key: 'all', status: true }, { root: true });

      // Dispatch network listener to set it up
      await dispatch('addNetworkListener');

      // Fetch the public app config
      await dispatch('fetchAppConfig');
    },

    async addNetworkListener({ commit }) {
      // Get initial network status
      const networkStatus = await Network.getStatus();
      commit('setNetworkStatus', networkStatus);

      // Add listener to set it to change
      Network.addListener('networkStatusChange', (networkStatus) => {
        commit('setNetworkStatus', networkStatus);
      });
    },

    async fetchAppConfig({ commit, state }) {
      return await authService.getAppConfig().then(async (response) => {
        if (response.ok) {
          // Save data to store
          const data = response?.data?.data ?? {};

          // Update appConfig
          if (data?.appConfig) {
            commit('setAppConfig', data.appConfig);
          }

          // Update localstorage
          await moduleStorage.update(state);
        }

        return response;
      });
    },

    async checkAppUpdateNative({ state }) {
      if (state.appConfig.appUpdateNativeEnabled) {
        // Set default values
        const platform = Capacitor.getPlatform();
        let deviceInfo = { name: '', id: '', build: '', version: '' };
        let appConfigVersion = state.appConfig?.appUpdateNativeIosVersion ?? '0.0.0';

        const defaultUpdateNotes = 'Good news! A new version of the app is available!';
        const alertData = {
          header: 'Update is Available!',
          message: state.appConfig?.appUpdateNativeNotes ?? defaultUpdateNotes,
          link: '',
        };

        const showNativeUpdateAlert = async () => {
          const alert = await alertController.create({
            header: alertData.header,
            message: alertData.message,
            cssClass: 'alert-style-1',
            buttons: [
              {
                text: 'Update Now',
                cssClass: 'alert-style-1',
                handler: () => {
                  window.open(alertData.link);
                },
              },
              { text: 'Later' },
            ],
          });
          await alert.present();
        };

        // For testing only
        if (isDev && platform === 'web') {
          // Set default values
          deviceInfo = { name: 'Core App', id: 'com.core.app', version: appVersion, build: '21' };
          // alertData.message = 'Good news! A new version of the app is available in the App Store!';
          alertData.link = state.appConfig.appUpdateNativeIosLink;
        }

        if (platform === 'ios') {
          deviceInfo = await App.getInfo();
          appConfigVersion = state.appConfig.appUpdateNativeIosVersion ?? '0.0.0';
          // alertData.message = 'Good news! A new version of the app is available in the App Store!';
          alertData.link = state.appConfig.appUpdateNativeIosLink;
        }

        if (platform === 'android') {
          deviceInfo = await App.getInfo();
          appConfigVersion = state.appConfig.appUpdateNativeAndroidVersion ?? '0.0.0';
          // alertData.message = 'Good news! A new version of the app is available in the Google Play Store!';
          alertData.link = state.appConfig.appUpdateNativeAndroidLink;
        }

        // Check if semver is valid, then compare major/minor only
        if (
          semver.valid(appConfigVersion) &&
          semver.valid(deviceInfo.version) &&
          semver.gt(appConfigVersion, deviceInfo.version)
        ) {
          showNativeUpdateAlert();
          return true;
        }
      }
      return false;
    },

    async checkAppUpdateOverTheAir({ state }) {
      const { appUpdateOtaEnabled, appUpdateOtaSilentInstall } = state.appConfig;
      const platform = Capacitor.getPlatform();

      if (appUpdateOtaEnabled && (platform === 'ios' || platform === 'android')) {
        const appConfigOtaVersion = state.appConfig?.appUpdateOtaAppVersion ?? '0.0.0';

        const defaultUpdateNotes = 'Good news! A new version of the app is available!';
        const updateModalData = reactive({
          updateNotes: state.appConfig?.appUpdateOtaNotes ?? defaultUpdateNotes,
          progressText: 'initializing...',
          progressPercentage: 0,
        });

        // Validate semver, make sure major/minor is the same then compare patch
        if (
          state.networkStatus.connected &&
          semver.valid(appConfigOtaVersion) &&
          semver.valid(appVersion) &&
          semver.major(appConfigOtaVersion) === semver.major(appVersion) &&
          semver.minor(appConfigOtaVersion) === semver.minor(appVersion) &&
          semver.patch(appConfigOtaVersion) > semver.patch(appVersion)
        ) {
          const update = await Deploy.checkForUpdate();

          // If there's an update, attempt to install it
          if (update.available) {
            if (appUpdateOtaSilentInstall) {
              await Deploy.downloadUpdate((progress) => {
                updateModalData.progressText = 'downloading...';
                updateModalData.progressPercentage = progress ? progress / 100 : 0;
              });
              await Deploy.extractUpdate((progress) => {
                updateModalData.progressText = 'extracting...';
                updateModalData.progressPercentage = progress ? progress / 100 : 0;
              });
            } else {
              // Confirm with the user to install now or later
              const alert = await alertController.create({
                header: 'Update Available!',
                message: updateModalData.updateNotes,
                cssClass: 'alert-style-1',
                buttons: [
                  {
                    text: 'Update Now',
                    handler: async () => {
                      // Open the modal popup
                      (async () => {
                        const modal = await modalController.create({
                          component: UpdateModal,
                          cssClass: 'modal-alert-style-3 auto-height',
                          backdropDismiss: false,
                          componentProps: {
                            modalData: updateModalData,
                          },
                        });
                        return modal.present();
                      })();

                      await Deploy.downloadUpdate((progress) => {
                        updateModalData.progressText = 'downloading...';
                        updateModalData.progressPercentage = progress ? progress / 100 : 0;
                      });
                      await Deploy.extractUpdate((progress) => {
                        updateModalData.progressText = 'extracting...';
                        updateModalData.progressPercentage = progress ? progress / 100 : 0;
                      });

                      updateModalData.progressText = 'restarting...';
                      updateModalData.progressPercentage = 1;

                      // Add delay to give them a heads up it's going to restart
                      setTimeout(async () => {
                        await Deploy.reloadApp();
                      }, 2000);
                    },
                  },
                  { text: 'Later' },
                ],
              });
              await alert.present();
            }
          }
        }
      }
    },

    async loadAppData({ state, commit, dispatch }) {
      // Load dashboard data right away
      const promises = [
        dispatch('dashboardModule/fetchDashboardStats', null, { root: true }),
        dispatch('dashboardModule/fetchDashboardTopCampaigns', null, { root: true }),
        dispatch('dashboardModule/fetchDashboardRecentActivity', null, { root: true }),
        dispatch('dashboardModule/fetchDashboardConfig', null, { root: true }),
        dispatch('statsModule/fetchStatsToday', null, { root: true }),
        dispatch('profileModule/fetchNewNotifications', { page: 1 }, { root: true }),
      ];

      if (state.appConfig.socialFeedEnable) {
        promises.push(
          dispatch('dashboardModule/fetchSocialFeedNotifications', { page: 1 }, { root: true })
        );
      }

      await Promise.all(promises).then(async () => {
        commit('setAppLoadingStage', 2);
        await moduleStorage.update(state);
      });

      // Load stats today data in the background to cascade the data fetch
      Promise.all([
        dispatch('offerModule/fetchOffers', null, { root: true }),
        dispatch('leaderboardModule/fetchLeaderboardAll', null, { root: true }),
        dispatch('profileModule/fetchProfile', null, { root: true }),
        dispatch('profileModule/fetchBillingHistory', null, { root: true }),
      ]).then(async () => {
        commit('setAppLoadingStage', 3);
        await moduleStorage.update(state);
      });

      // Non essential calls
      dispatch('teamsModule/fetchTeamStats', null, { root: true });
    },
  },
};

export default module;
