import { Module } from 'vuex';
import { RootState } from '@core-app/store';
import { getData, setData } from '@core-app/utils/storage';

export const ROLES = {
  affiliateUser: 'affiliate_user',
  employee: 'employee',
};

export const PERMISSIONS = {
  teamsManagerView: 'App.teams_manager.view',
  contentLockerView: 'App.content_locker.view',
};

// Setup initial states
const initialState = {
  roles: [] as string[],
  permissions: [] as string[],
};
export type InitialState = typeof initialState;

// Module storage functions
const moduleStorage = {
  moduleName: 'permissionsModule',
  fetch: async function () {
    return await getData(this.moduleName);
  },
  update: async function (state: typeof initialState) {
    await setData(this.moduleName, state);
  },
};

// Setup state module
const module: Module<InitialState, RootState> = {
  namespaced: true,
  state: initialState,
  getters: {
    hasRole: (state) => (searchedRole: string) => {
      return state.roles.some((role: string) => role === searchedRole);
    },
    hasPermission: (state) => (searchedPermission: string) => {
      return state.permissions.some((permission: string) => permission === searchedPermission);
    },

    // Specific permissions
    hasTeamsManagerViewPermission: (state, getters) => {
      return getters.hasPermission(PERMISSIONS.teamsManagerView);
    },
    hasContentLockerView: (state, getters) => {
      return getters.hasPermission(PERMISSIONS.contentLockerView);
    },
  },
  mutations: {
    updateState(state, data) {
      Object.assign(state, data);
    },

    setRoles(state, payload) {
      state.roles = payload;
    },
    setPermissions(state, payload) {
      state.permissions = payload;
    },
  },

  actions: {
    async updateStateFromStorage({ commit }) {
      return moduleStorage.fetch().then((data) => {
        if (data) {
          commit('updateState', data);
        }
      });
    },

    async updateRoles({ commit, state }, payload) {
      commit('setRoles', payload);
      await moduleStorage.update(state);
    },

    async updatePermissions({ commit, state }, payload) {
      commit('setPermissions', payload);
      await moduleStorage.update(state);
    },
  },
};

export default module;
