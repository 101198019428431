import { useApi } from './api';
import {
  UserOptionsPushNotifications,
  UserOptionsEmailNotifications,
} from './notifications.service';

export interface UserOptions {
  referralLink: string;
  onboardingViewed: boolean;
  notifications: UserOptionsPushNotifications;
  emailNotifications: UserOptionsEmailNotifications;
  paymentRequest: {
    enabled: boolean;
    previousRequestDate: string;
    w9Required: boolean;
  };
}

export interface ProfileAvatar {
  originalUrl: string;
  thumbnailUrl: string;
  optimizedUrl: string;
}

export interface Profile {
  affiliateId: number | string;
  affiliateUserId: number;
  firstName: string;
  lastName: string;
  email: string;
  paymentMethod: string;
  paymentTerms: string;
  w9Filed: string;
  address1: string;
  address2: string;
  city: string;
  company: string;
  country: string;
  phone: string;
  region: string;
  zipcode: string;

  referralId: string;
  referralName: string;

  avatar: ProfileAvatar;
  avatarUrl: string;
  referralLink: string;
  username: string;
}

export interface Billing {
  isIndividual: number;
  otherDetails: string;
  paypalEmail: string;
  checkPayableTo: string;
  checkAddress1: string;
  checkAddress2: string;
  checkCity: string;
  checkRegion: string;
  checkCountry: string;
  checkZipcode: string;
  directDepositAccountHolder: string;
  directDepositAccountNumber: string;
  directDepositBankName: string;
  directDepositRoutingNumber: string;
  directDepositOtherDetails: string;
  wireBeneficiaryName: string;
  wireAccountNumber: string;
  wireBankName: string;
  wireRoutingNumber: string;
  wireOtherDetails: string;
}

export interface UpdateProfilePayload {
  email: string;
  company: string;
  firstName: string;
  lastName: string;
  address1: string;
  address2: string;
  city: string;
  region: string;
  zipcode: string;
  phone: string;
  country: string;
}

export interface UpdateBillingDirectDepositPayload {
  paymentMethod: string;
  directDepositAccountHolder: string;
  directDepositAccountNumber: string;
  directDepositBankName: string;
  directDepositRoutingNumber: string;
  directDepositOtherDetails: string;
}
export interface UpdateBillingOtherPayload {
  paymentMethod: string;
  otherDetails: string;
}
export interface UpdateBillingPaypalPayload {
  paymentMethod: string;
  paypalEmail: string;
}
export interface UpdateBillingWirePayload {
  paymentMethod: string;
  wireBeneficiaryName: string;
  wireAccountNumber: string;
  wireBankName: string;
  wireRoutingNumber: string;
  wireOtherDetails: string;
}

type UpdateBillingPayload = UpdateBillingDirectDepositPayload &
  UpdateBillingOtherPayload &
  UpdateBillingPaypalPayload &
  UpdateBillingWirePayload;

export const useProfileService = () => {
  const api = useApi();

  const getProfile = async () => {
    return await api.get('/profile');
  };

  const updateProfile = async (payload: UpdateProfilePayload) => {
    return await api.post('/profile', payload);
  };

  const updateChangePassword = async (payload: {
    password: string;
    passwordConfirmation: string;
  }) => {
    return await api.post('/profile/password', payload);
  };

  const updateOnboardingViewed = async (payload: { onboardingViewed: boolean }) => {
    return await api.post('/profile/options', { options: payload });
  };

  const uploadAvatar = async (payload: { file: File }) => {
    return await api.postUpload('/profile/upload-avatar', payload);
  };

  return {
    api,
    getProfile,
    updateProfile,
    updateChangePassword,
    updateOnboardingViewed,
    uploadAvatar,
  };
};

export const useBillingService = () => {
  const api = useApi();

  const getBillingHistory = async () => {
    return await api.get('/profile/billing/history');
  };

  const getBillingInvoice = async (payload: { invoiceId: number }) => {
    return await api.get('/profile/billing/invoice', payload);
  };

  const updateBilling = async (payload: UpdateBillingPayload) => {
    return await api.post('/profile/billing', payload);
  };

  const requestPayment = async () => {
    return await api.post('/profile/billing/request-payment');
  };

  return { api, getBillingHistory, getBillingInvoice, updateBilling, requestPayment };
};
