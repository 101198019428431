import { Module } from 'vuex';
import { RootState } from '@core-app/store';
import { getData, setData } from '@core-app/utils/storage';
import { useTeamsService } from '@core-app/services/teams.service';
import { ProfileAvatar } from '@core-app/services/profile.service';

export interface TeamPerformance {
  name: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  lastMonth: {
    payout: number;
    commissions: number;
  };
  thisMonth: {
    payout: number;
    commissions: number;
  };
  referralCount: number;
}

interface TeamEntryStats {
  payout: number;
  clicks: number;
  conversions: number;
  commissions: number;
  cvr: number;
  epc: number;
}

export interface TeamEntry {
  rank: number;
  id: number;
  name: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  dateAdded: string;
  avatar: ProfileAvatar;
  thisMonth: TeamEntryStats;
  lastMonth: TeamEntryStats;
}

// Setup initial states
const initialState = {
  performance: {} as TeamPerformance,
  entries: [] as TeamEntry[],
};
export type InitialState = typeof initialState;

// Get API Service
const teamsService = useTeamsService();

// Module storage functions
const moduleStorage = {
  moduleName: 'teamsModule',
  fetch: async function () {
    return await getData(this.moduleName);
  },
  update: async function (state: typeof initialState) {
    await setData(this.moduleName, state);
  },
};

// Setup state module
const module: Module<InitialState, RootState> = {
  namespaced: true,
  state: initialState,
  getters: {},
  mutations: {
    updateState(state, data) {
      Object.assign(state, data);
    },

    updatePerformance(state, payload) {
      state.performance = payload;
    },
    updateEntries(state, payload) {
      state.entries = payload;
    },
  },

  actions: {
    async updateStateFromStorage({ commit }) {
      return moduleStorage.fetch().then((data) => {
        if (data) {
          commit('updateState', data);
        }
      });
    },

    async fetchTeamStats({ commit, state }) {
      return await teamsService.getTeamStats().then(async (response) => {
        if (response.ok) {
          // Save data to store
          const data = response?.data?.data ?? {};

          if (data?.performance) {
            commit('updatePerformance', data.performance);
          }
          if (data?.entries) {
            commit('updateEntries', data.entries);
          }

          // Update localstorage
          await moduleStorage.update(state);
        } else {
          console.log('Unable to fetch teams stats');
        }
        return response;
      });
    },
  },
};

export default module;
