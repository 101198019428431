import { Module } from 'vuex';
import { RootState } from '@core-app/store';
import { getData, setData } from '@core-app/utils/storage';
import { useStatsService } from '@core-app/services/stats.service';

export interface DataSetData {
  date: string;
  current: {
    revenue: string;
    clicks: string;
    conversions: string;
  };
  previous: {
    revenue: string;
    clicks: string;
    conversions: string;
  };
}

export interface DataSet {
  headers: {
    col1?: string;
    col2?: string;
    col3?: string;
  };
  data: DataSetData[];
}

export interface StatsData {
  payout: string | number;
  clicks: string | number;
  conversions: string | number;
  cvr: string | number;
  epc: string | number;
}

export interface TotalStats {
  currentTitle: string;
  currentData: StatsData;
  previousTitle: string;
  previousData: StatsData;
}

export interface OfferStats {
  id: number | string;
  clicks: number | string;
  conversions: number | string;
  cvr: number | string;
  epc: number | string;
  offerId: number;
  offerName: string;
  offerPayout: number | string;
  offerStatus: string;
  offerThumbnailUrl: string;
  payout: number | string;
}

export interface CountryStats {
  id: number | string;
  clicks: number | string;
  conversions: number | string;
  countryCode: string;
  countryName: string;
  cvr: number | string;
  epc: number | string;
  payout: number | string;
}

export interface SubidStats {
  id: number | string;
  affiliateSource: string;
  clicks: number | string;
  conversions: number | string;
  countryCode: string;
  countryName: string;
  cvr: number | string;
  epc: number | string;
  payout: number | string;
  offerId: number;
  offerName: string;
  offerPayout: number | string;
  offerStatus: string;
  offerThumbnailUrl: string;
}

export interface ContentLockerStats {
  contentLockerKey: string;
  name: string;
  imageUrl: string;

  impressions: number | string;
  clicks: number | string;
  conversions: number | string;
  cvr: number | string;
  epc: number | string;
  payout: number | string;
  offers: OfferStats[];
}

// Setup initial states
const initialState = {
  stats: [],
  totals: [],
  statsToday: {},
  statsThisWeek: {},
  statsThisMonth: {},
  statsThisYear: {},
};
export type InitialState = typeof initialState;

// Get API Service
const statsService = useStatsService();

// Module storage functions
const moduleStorage = {
  moduleName: 'statModule',
  fetch: async function () {
    return await getData(this.moduleName);
  },
  update: async function (state: typeof initialState) {
    await setData(this.moduleName, state);
  },
};

// Setup state module
const module: Module<InitialState, RootState> = {
  namespaced: true,
  state: initialState,
  getters: {},
  mutations: {
    updateState(state, data) {
      Object.assign(state, data);
    },
    updateStats(state, payload) {
      state.stats = payload;
    },
    updateTotals(state, payload) {
      state.totals = payload;
    },

    updateStatsToday(state, payload) {
      state.statsToday = payload;
    },
    updateStatsThisWeek(state, payload) {
      state.statsThisWeek = payload;
    },
    updateStatsThisMonth(state, payload) {
      state.statsThisMonth = payload;
    },
    updateStatsThisYear(state, payload) {
      state.statsThisYear = payload;
    },
  },

  actions: {
    async updateStateFromStorage({ commit }) {
      return moduleStorage.fetch().then((data) => {
        if (data) {
          commit('updateState', data);
        }
      });
    },

    async fetchStatsToday({ commit, state }) {
      return await statsService.getStatsToday().then(async (response) => {
        if (response.ok) {
          // Save data to store
          const data = response?.data?.data ?? {};

          if (data) {
            commit('updateStatsToday', data);

            if (data.currentTotals?.payout) {
              commit('dashboardModule/mutateDashboardStatsPayoutToday', data.currentTotals.payout, {
                root: true,
              });
            }
          }

          // Update localstorage
          await moduleStorage.update(state);
        } else {
          console.log('Unable to fetch stats');
        }
        return response;
      });
    },

    async fetchStatsThisWeek({ commit, state }) {
      return await statsService.getStatsThisWeek().then(async (response) => {
        if (response.ok) {
          // Save data to store
          const data = response?.data?.data ?? {};

          if (data) {
            commit('updateStatsThisWeek', data);
          }

          // Update localstorage
          await moduleStorage.update(state);
        } else {
          console.log('Unable to fetch stats');
        }
        return response;
      });
    },

    async fetchStatsThisMonth({ commit, state }) {
      return await statsService.getStatsThisMonth().then(async (response) => {
        if (response.ok) {
          // Save data to store
          const data = response?.data?.data ?? {};

          if (data) {
            commit('updateStatsThisMonth', data);
          }

          // Update localstorage
          await moduleStorage.update(state);
        } else {
          console.log('Unable to fetch stats');
        }
        return response;
      });
    },

    async fetchStatsThisYear({ commit, state }) {
      return await statsService.getStatsThisYear().then(async (response) => {
        if (response.ok) {
          // Save data to store
          const data = response?.data?.data ?? {};

          if (data) {
            commit('updateStatsThisYear', data);
          }

          // Update localstorage
          await moduleStorage.update(state);
        } else {
          console.log('Unable to fetch stats');
        }
        return response;
      });
    },
  },
};

export default module;
