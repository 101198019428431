import { useApi } from './api';
import { getDeviceInfo, appVersion } from '@core-app/utils/helpers';

import { RegistrationForm } from '@core-app/store/auth.module';

export const useAuthService = () => {
  const api = useApi();

  const getAppConfig = async () => {
    return await api.get('/app-config');
  };

  const login = async (user: { username: string; password: string }) => {
    const device = await getDeviceInfo();

    return await api.post('/login', { ...user, appVersion, device });
  };

  const checkUniqueEmail = async (payload: { email: string }) => {
    return await api.post('/register/check-email', payload);
  };

  const register = async (registrationForm: RegistrationForm) => {
    const device = await getDeviceInfo();
    return await api.post('/register', { ...registrationForm, appVersion, device });
  };

  const forgotPassword = async (email: { email: string }) => {
    const device = await getDeviceInfo();
    return await api.post('/forgot-password', { ...email, appVersion, device });
  };

  const resetPassword = async (payload: {
    token: string;
    password: string;
    passwordConfirmation: string;
  }) => {
    return await api.post('/reset-password', payload);
  };

  const verifyToken = async (token: string) => {
    const deviceInfo = await getDeviceInfo();

    api.setAuthToken(token);
    return await api.post('/verify-token', { appVersion, device: deviceInfo });
  };

  const deactivateAccount = async () => {
    return await api.post('/deactivate-account');
  };

  return {
    api,
    getAppConfig,
    login,
    checkUniqueEmail,
    register,
    forgotPassword,
    resetPassword,
    verifyToken,
    deactivateAccount,
  };
};
