import { toastController, ToastOptions } from '@ionic/vue';
import { create, ApiResponse } from 'apisauce';
import { Device } from '@capacitor/device';
import { Browser } from '@capacitor/browser';
import { close } from 'ionicons/icons';

import store from '@core-app/store';

export const appVersion = __APP_VERSION__ || '0.0.0';
export const appName = import.meta.env.VITE_APP_NAME || 'app';
export const appTitle = import.meta.env.VITE_APP_TITLE || 'App';

export const isTapmob = import.meta.env.VITE_APP_NAME === 'tapmob';
export const isKVRMA = import.meta.env.VITE_APP_NAME === 'kvrma';

// export const supportEmail = import.meta.env.VITE_APP_SUPPORT_EMAIL || '';
export const sentryUrl = import.meta.env.VITE_APP_SENTRY_URL || '';

export const nodeEnvironment = import.meta.env.VITE_APP_ENV;
export const isProd = import.meta.env.VITE_APP_ENV === 'production';
export const isStaging = import.meta.env.VITE_APP_ENV === 'staging';
export const isDev = !isProd && !isStaging;

export const useToggleDark = () => {
  const setDarkMode = () => {
    const { authModule, profileModule } = store.state;

    if (profileModule && authModule) {
      const isDarkMode = authModule.token !== '' ? profileModule.darkMode : false;
      const containsDarkClass = document.body.classList.contains('dark');

      if ((isDarkMode && !containsDarkClass) || (!isDarkMode && containsDarkClass)) {
        document.body.classList.toggle('dark', isDarkMode);
        // setChartDarkMode(isDarkMode);
      }
    }
  };

  const toggleDark = async () => {
    await store.dispatch('profileModule/toggleDarkMode');
    setDarkMode();
  };

  return { toggleDark, setDarkMode };
};

export const openToast = {
  success: async (message: string, position?: ToastOptions['position'], duration?: number) => {
    const toast = await toastController.create({
      message,
      color: 'success',
      duration: duration ?? 5000,
      keyboardClose: true,
      position: position ?? 'bottom',
      buttons: [
        {
          icon: close,
          role: 'cancel',
        },
      ],
    });
    return toast.present();
  },
  error: async (message: string, position?: ToastOptions['position'], duration?: number) => {
    const toast = await toastController.create({
      message,
      color: 'danger',
      duration: duration ?? undefined,
      keyboardClose: true,
      position: position ?? 'bottom',
      buttons: [
        {
          icon: close,
          role: 'cancel',
        },
      ],
    });
    return toast.present();
  },
  info: async (message: string, position?: ToastOptions['position'], duration?: number) => {
    const toast = await toastController.create({
      message,
      color: 'light',
      duration: duration ?? undefined,
      keyboardClose: true,
      position: position ?? 'bottom',
      buttons: [
        {
          // side: 'start',
          icon: close,
          role: 'cancel',
        },
      ],
    });
    return toast.present();
  },
};

export const getIpAddress = async () => {
  const publicIpServices = [
    'https://api.ipify.org?format=json',
    'https://api64.ipify.org?format=json',
    'https://jsonip.com/',
    'https://myexternalip.com/json',
  ];
  const api = create({ baseURL: publicIpServices[0] });

  const response = (await api.get('')) as ApiResponse<{ ip: string }>;
  if (response.ok && response.data?.ip) {
    return response.data.ip;
  } else {
    return '';
  }
};

export const getDeviceInfo = async () => {
  const info = await Device.getInfo();
  return {
    platform: info.platform || 'n/a',
    operatingSystem: info.operatingSystem || 'n/a',
    osVersion: info.osVersion || 'n/a',
    model: info.model || 'n/a',
    manufacturer: info.manufacturer || 'n/a',
  };
};

export const openBrowserFullscreen = async (url: string) => {
  await Browser.open({ url, presentationStyle: 'fullscreen' });
};
export const openBrowserPopover = async (url: string) => {
  await Browser.open({ url, presentationStyle: 'popover' });
};

export const isLoggedIn = () => {
  return store.getters['authModule/authenticated'];
};

export const isValidUrl = (urlString: string) => {
  let url;
  try {
    url = new URL(urlString);
  } catch (e) {
    return false;
  }
  return url.protocol === 'http:' || url.protocol === 'https:';
};
