import { Module } from 'vuex';
import { RootState } from '@core-app/store';
import { getData, setData } from '@core-app/utils/storage';
import { useOfferService } from '@core-app/services/offer.service';

interface Creative {
  id: number;
  fileName: string;
  mimeType: string;
  size: number;
  originalUrl: string;
  thumbnailUrl: string;
  order: number;
  isVisibleInApp: boolean;
}

interface Goal {
  id: number;
  offerId: number;
  name: string;
  defaultPayout: number | string;
}

interface Payout {
  deviceId: number;
  deviceName: string;
  country: string;
  payout: number;
}

export interface DomainLink {
  customLinkId: number;
  domainId: number;
  url: string;
  direct: boolean;
  type: string;
  typeName: string;
}

interface Badge {
  color: string;
  text: string;
}

export interface Offer {
  id: number;
  advertiserId: number;
  name: string;
  description: string;
  note: string;
  status: string;
  previewUrl: string;
  payoutType: string;
  defaultPayout: number[] | number | string;
  requireApproval: boolean;
  isPrivate: boolean;
  isExpired: boolean;
  type: string;
  approvalStatus: string;
  isApproved: boolean;
  payoutTypeDesc: string;
  payouts: Payout[];
  devices: string[];
  thumbnail: string;
  domainLinks: DomainLink[];
  creatives: Creative[];
  badges: Badge[];
  isCapped: boolean;
  thumbnailUrl: string;
  countries: string[];
  goals: Goal[];
  tags: string[];
  categories: Category[];
}

interface Category {
  id: number;
  name: string;
  status?: string;
}

export interface OfferGroup {
  category: Category;
  offers: Offer[];
}

interface OfferApproval {
  offerId: number;
  status: string;
}

// Setup initial states
const initialState = {
  offersConfig: {},
  offerList: [] as OfferGroup[],
  offerCount: 0,
  imageList: [],
  availableFilters: {
    tags: [],
    countries: [],
    devices: [],
    categories: [],
  },
  filters: {
    tags: [] as string[],
    countries: [] as string[],
    devices: [] as string[],
    categories: [] as string[],
  },
  sort: 'recommended',
  searchTerm: '',
  offerApprovals: [] as OfferApproval[],
};
export type InitialState = typeof initialState;

// Get API Service
const offerService = useOfferService();

// Module storage functions
const moduleStorage = {
  moduleName: 'offerModule',
  fetch: async function () {
    return await getData(this.moduleName);
  },
  update: async function (state: typeof initialState) {
    await setData(this.moduleName, state);
  },
};

// Setup state module
const module: Module<InitialState, RootState> = {
  namespaced: true,
  state: initialState,
  getters: {
    getOfferList(state) {
      if (!state.offerList) {
        return [];
      }

      return state.offerList.reduce((acc: Offer[], curr: { offers: Offer[] }) => {
        return acc.concat(curr.offers);
      }, []);
    },

    getOfferListFiltered(state) {
      const { searchTerm, filters, sort } = state;

      if (!state.offerList) {
        return [];
      }

      return state.offerList.map((offerGroups: OfferGroup) => {
        // Filter and Search Offer List
        const filteredOffers = offerGroups.offers.filter((offer: Offer) => {
          // Tags/Categories Filter
          const tagsFilter =
            filters?.tags && filters.tags.length
              ? offer.tags.some((tag: string) => {
                  return filters.tags.includes(tag);
                })
              : true;

          // Countries Filter
          const countriesFilter =
            filters?.countries && filters.countries.length
              ? offer.countries.some((country: string) => {
                  return filters.countries.includes(country);
                })
              : true;

          // Devices Filter
          const devicesFilter =
            filters?.devices && filters.devices.length
              ? offer.devices.some((device: string) => {
                  return filters.devices.includes(device);
                })
              : true;

          // Search Term Filter
          const searchFilter =
            searchTerm && searchTerm.length > 0
              ? JSON.stringify(offer).toLowerCase().includes(searchTerm.toLowerCase())
              : true;

          return searchFilter && tagsFilter && countriesFilter && devicesFilter;
        });

        // Sort offer list
        const sortedOffers = filteredOffers.sort((offer1, offer2) => {
          let a: number | string = 0,
            b: number | string = 0,
            comparison = 0;

          if (!sort.length) {
            return comparison;
          }

          // Set a, b
          if (sort.includes('alpha')) {
            a = offer1.name.trim().toUpperCase();
            b = offer2.name.trim().toUpperCase();
          }

          if (sort.includes('payout')) {
            a = parseFloat(offer1.defaultPayout as string);
            b = parseFloat(offer2.defaultPayout as string);
          }

          // Sort ascending / descending
          if (sort.includes('asc')) {
            if (a > b) {
              comparison = 1;
            } else if (a < b) {
              comparison = -1;
            }
          }
          if (sort.includes('desc')) {
            if (a > b) {
              comparison = -1;
            } else if (a < b) {
              comparison = 1;
            }
          }
          return comparison;
        });

        return {
          category: offerGroups.category,
          offers: sortedOffers,
        };
      });
    },

    getOffer: (state, getters) => (id: number) => {
      return getters.getOfferList.find((offer: Offer) => offer.id === id);
    },
    getPreviousNextOfferIds: (state, getters) => (id: number) => {
      const offerIds = {
        previousId: 0,
        nextId: 0,
      };

      // Get index of the current offer ID
      const index = getters.getOfferList.findIndex((offer: Offer) => offer.id === id);

      if (index > 0) {
        offerIds.previousId = getters.getOfferList[index - 1].id;
      }

      if (index < getters.getOfferList.length - 1) {
        offerIds.nextId = getters.getOfferList[index + 1].id;
      }

      return offerIds;
    },

    getOfferApprovalStatus: (state) => (offerId: number) => {
      const offerApproval = state.offerApprovals.filter((value) => value.offerId === offerId);

      if (offerApproval && offerApproval[0]) {
        return offerApproval[0].status;
      }

      return 'none';
    },
  },
  mutations: {
    updateState(state, data) {
      Object.assign(state, data);
    },
    updateOffers(state, offers) {
      state.offerList = offers;
    },
    mutateOffersConfig(state, payload) {
      state.offersConfig = payload;
    },
    updateImages(state, images) {
      state.imageList = images;
    },
    updateAvailableFilters(state, payload) {
      state.availableFilters = payload;
    },
    updateFiltersState(state, payload) {
      state.filters = payload;
    },
    mutateSort(state, payload) {
      state.sort = payload;
    },
    updateSearchTermState(state, payload) {
      state.searchTerm = payload;
    },
    addOfferApproval(state, payload: { offerId: number; status: string }) {
      state.offerApprovals = [
        ...state.offerApprovals.filter((value) => value.offerId !== payload.offerId),
        payload,
      ];
    },
    removeOfferApproval(state, offerId) {
      state.offerApprovals = state.offerApprovals.filter((value) => value.offerId !== offerId);
    },
  },

  actions: {
    async updateStateFromStorage({ commit }) {
      return moduleStorage.fetch().then((data) => {
        if (data) {
          data.searchTerm = initialState.searchTerm;
          commit('updateState', data);
        }
      });
    },

    async updateOfferFilters({ commit, state }, payload) {
      commit('updateFiltersState', payload);
      await moduleStorage.update(state);
    },

    async updateSort({ commit, state }, payload) {
      commit('mutateSort', payload);
      await moduleStorage.update(state);
    },

    async updateSearchTerm({ commit, state }, payload) {
      commit('updateSearchTermState', payload);
      await moduleStorage.update(state);
    },

    async sendOfferApprovalRequest({ commit, state }, payload) {
      return await offerService.createOfferApproval(payload).then(async (response) => {
        if (response.ok) {
          // Add offer approval to pending list, then update local storage
          commit('addOfferApproval', { offerId: payload.offerId, status: 'pending' });

          // Update localstorage
          await moduleStorage.update(state);
        } else {
          console.log('Unable to submit offer approval request');
        }
        return response;
      });
    },

    async createOfferCustomLink(context, payload) {
      return await offerService.createOfferCustomLink(payload).then(async (response) => {
        return response;
      });
    },

    async deleteOfferCustomLink(context, payload) {
      return await offerService.deleteOfferCustomLink(payload).then(async (response) => {
        return response;
      });
    },

    async fetchOffers({ commit, state, getters }) {
      return await offerService.getOffers().then(async (response) => {
        if (response.ok) {
          // Save data to store
          const data = response?.data?.data ?? {};

          if (data?.offerList) {
            commit('updateOffers', data.offerList);
          }

          if (data?.offersConfig) {
            commit('mutateOffersConfig', data.offersConfig);
          }

          // Set sort if useServerOption is true
          if (
            data?.offersConfig?.sort?.useServerOption &&
            data?.offersConfig?.sort?.defaultOption
          ) {
            commit('mutateSort', data.offersConfig.sort.defaultOption);
          }

          // Remove offer approvals from state if there is an update from the server
          const offers = getters['getOfferList'];
          state.offerApprovals.forEach((approval) => {
            const offer = offers.find((off: { id: number }) => off.id === approval.offerId);

            // if the offer approval status on the server does not equal none
            if (
              offer &&
              offer.approvalStatus &&
              ['approved', 'pending', 'rejected'].includes(offer.approvalStatus)
            ) {
              commit('removeOfferApproval', approval.offerId);
            }
          });

          if (data?.imageList) {
            commit('updateImages', data.imageList);
          }

          if (data?.filters) {
            commit('updateAvailableFilters', data.filters);
          }

          // Update localstorage
          await moduleStorage.update(state);
        } else {
          console.log('Unable to fetch offers');
        }
        return response;
      });
    },
  },
};

export default module;
