import { useApi } from './api';

interface GetDataPayload {
  columns?: string[];
  filter?: string[];
  search?: string;
  page?: string | number;
  perPage?: number;
  sortField?: string;
  sortDirection?: string;
}

export interface Domain {
  id: number;
  protocol: 'http' | 'https';
  domain: string;
  url: string;
}

export interface Template {
  id: number;
  name: string;
  imageUrl: string;
  templateDefaults: {
    template: { [key: string]: string | number | boolean };
    link: { [key: string]: string | number | boolean };
  };
}

export interface MediaFile {
  id: number;
  name: string;
  fileName: string;
  mimeType: string;
  originalUrl: string;
  thumbnailUrl: string;
  optimizedUrl: string;
}

export interface ContentLocker {
  id: number;
  contentLockerKey: string;
  name: string;
  description: string;
  templateId: number;
  domainId: number;
  redirectUrl: string;
  numOffersUnlock: number;
  status: string;
  url: string;
  mediaFiles: MediaFile[];
  options: {
    [key: string]: string | number | boolean;
  };
}

export const useLandingPageService = () => {
  const api = useApi();

  const getContentLockers = async (payload: GetDataPayload) => {
    return await api.get('/content-lockers', payload);
  };

  const deleteContentLocker = async (payload: { id: number }) => {
    return await api.remove(`/content-lockers/${payload.id}`);
  };

  const editContentLocker = async (id: number, payload: ContentLocker) => {
    return await api.post(`/content-lockers/${id}/edit`, payload);
  };

  const createContentLocker = async (payload: ContentLocker) => {
    return await api.post('/content-lockers/create', payload);
  };

  const deleteContentLockerImage = async (payload: { id: number; mediaFileId: number }) => {
    return await api.remove(`/content-lockers/${payload.id}/media-files/${payload.mediaFileId}`);
  };

  const uploadContentLockerImages = async (payload: {
    contentLockerId: number;
    files: File[];
    uploadedImageSelectionIndex: { [key: string]: number };
  }) => {
    return await api.postUpload(`/content-lockers/${payload.contentLockerId}/media-files/upload`, {
      files: payload.files,
      uploadedImageSelectionIndex: payload.uploadedImageSelectionIndex,
    });
  };
  return {
    api,
    getContentLockers,
    deleteContentLocker,
    editContentLocker,
    createContentLocker,
    deleteContentLockerImage,
    uploadContentLockerImages,
  };
};
