import { useApi } from './api';

export const useDashboardService = () => {
  const api = useApi();

  // const getDashboard = async () => {
  //   return await api.get('/dashboard');
  // };
  const getDashboardStats = async () => {
    return await api.get('/dashboard/stats');
  };
  const getDashboardTopCampaigns = async () => {
    return await api.get('/dashboard/top-campaigns');
  };
  const getDashboardRecentActivity = async () => {
    return await api.get('/dashboard/recent-activity');
  };
  const getDashboardConfig = async () => {
    return await api.get('/dashboard/config');
  };

  return {
    api,
    // getDashboard,
    getDashboardStats,
    getDashboardTopCampaigns,
    getDashboardRecentActivity,
    getDashboardConfig,
  };
};
